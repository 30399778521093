import axios from "./api";
import { buildQueryParams } from "../utils/buildQueryParams";

class ExperienciasService {
  async getExclusiveExperiences(params){
    const queryParams = buildQueryParams(params);
    return axios.get(`/exclusive-experiences?${queryParams}`);
  }
  async getExclusiveExperience(id) {
    return axios.get('/exclusive-experiences/'+id);
  }
  async exclusiveExperiencesRegister(request) {
    const { id, answer } = request;
    return axios.post('/exclusive-experiences/'+id+'/register', {answer: answer});
  }
}

export default new ExperienciasService();
