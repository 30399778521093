import CountryService from "../services/countries.service";

export const countries = {
  namespaced: true,
  actions: {
    get({ commit }) {
      return CountryService.get()
        .then(
          (country) => {
            return Promise.resolve(country);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((e) => {
          return Promise.reject(e);
        });
    }
  },
  mutations: {
  },
  getters: {
  },
};
