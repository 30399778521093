export const modalService = {
    state() {
        return {
            exclusiveServiceModal: false,
        }
    },
    mutations: {
        updateModalExclusiveService(state, value) {
            state.exclusiveServiceModal = value
        }
    },
    actions: {
        exclusiveServiceModal({ commit, state }) {
            commit('updateModalExclusiveService', !state.exclusiveServiceModal)
        }
    },
    getters: {
        exclusiveServiceModalOpened: state => state.exclusiveServiceModal
    }
}