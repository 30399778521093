import api from "./api";

class ProductService {
  getCategories() {
    return api.get('products/categories')
  }

  getSubCategories(id) {
    return api.get(`products/categories/${id}/subCategories`)
  }

  registerProduct(product) {
    return api.post(`users/members/${product.memberId}/products`, product)
  }

  getMemberProducts(){
    return api.get(`users/members/products`)
  }
}

export default new ProductService();
