import api from "./api";

const banners = `mocks${import.meta.env.VITE_BASE_PATH}banners.home.json`
var jsonFinal = "" 
class BannerHomeService {
  async get() {
    if(!jsonFinal){
      const resp = await fetch(banners)
      jsonFinal = await resp.json()
    }
    return jsonFinal
  }
}

export default new BannerHomeService();
