import SocialMediaService from "../services/social-media.service";

export const socialMedias = {
  namespaced: true,
  actions: {
    get({ commit }) {
      return SocialMediaService.get()
        .then(
          (socialMedias) => {
            return Promise.resolve(socialMedias);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((e) => {
          return Promise.reject(e);
        });
    },
    getTypes({ commit }) {
      return SocialMediaService.getTypes()
        .then(
          (socialMediaTypes) => {
            return Promise.resolve(socialMediaTypes);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((e) => {
          return Promise.reject(e);
        });
    }
  },
  mutations: {
  },
  getters: {
  },
};
