
<template>
	<div class="checkbox-wrapper">
		<input @input="$emit('update:modelValue', !valorChech)" v-model="valorChech" :id="inputId" type="checkbox" :disabled="isDisabled" aria-hidden="true">
		<label>
			<slot></slot>
		</label>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			type: String,
			required: true,
		},
		nameObj:{
			type: String,
			required: true,
		},
		id: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data(){
		return{
			valorChech: false
		}
	},
	computed: {
		inputId() {
			return `${this.id}`;
		},
		wrapperClass() {
			return `checkbox-wrapper-${this.id}`;
		},
		isDisabled() {
			return this.disabled;
		},
	},
};
</script>

<style lang="sass" scoped>
@use '../../assets/sass/utilities/_colors' as *

.checkbox-wrapper 
	// width: 26px
	height: fit-content
	display: flex
	flex-direction: row !important
	// align-items: center
	justify-content: center
	gap: 0


.checkbox-wrapper input[type=checkbox]
	--size-input: 18px
	--active: var(--lg-red-active)
	--active-inner: var(--white)
	--focus: 2px rgba(var(--lg-red-active-rgb), 0.3)
	--border: var(--color-grey-700)
	--border-hover: var(--lg-red-active)
	--background: var(--white)
	--disabled: var(--color-grey-700)
	--disabled-inner: var( --color-grey-200)
	--bd-r: 4px

	-webkit-appearance: none
	-moz-appearance: none
	height: var(--size-input)
	outline: none
	display: inline-block
	vertical-align: top
	position: relative
	margin: 0
	cursor: pointer
	border: 1px solid var(--bc, var(--border))
	background: var(--b, var(--background))
	transition: background 0.3s, border-color 0.3s, box-shadow 0.2s

	&:after
		content: ""
		display: block
		left: 0
		top: 0
		position: absolute
		transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s)

	&:checked
		--b:var(--active)
		--bc:var(--active)
		--d-o:.3s
		--d-t:.6s
		--d-t-e:cubic-bezier(0.2, 0.85, 0.32, 1.2)

	&:disabled
		--b:var(--disabled)
		cursor: not-allowed
		opacity: 0.9

		&:checked
			--b:var(--disabled-inner)
			--bc:var(--border)

		+ label
			cursor: not-allowed
			

	&:hover:not(:checked):not(:disabled)
		--bc:var(--border-hover)

	&:focus
		box-shadow: 0 0 0 var(--focus)

	&:not(.switch)
		width: var(--size-input)
		min-width: var(--size-input)


		&:after
			opacity: var(--o, 0)

		&:checked
			--o:1

	border-radius: var(--bd-r)

	&:after
		width:  calc( var(--size-input)  / 3.5 )
		height:  calc( var(--size-input) / 2)
		border: 2px solid var(--active-inner)
		border-top: 0
		border-left: 0
		left: calc( var(--size-input) / 3.7)
		top: calc( var(--size-input) / 9)
		transform: rotate(var(--r, 20deg))

	&:checked
		--r:43deg

	+ label
		display: inline-block
		vertical-align: middle
		margin-left: 5px

.checkbox-wrapper *
	box-sizing: inherit

	&:before, &:after
		box-sizing: inherit


</style>





