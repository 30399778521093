import axiosInstance from "./api";
import TokenService from "./token.service";
import AuthService from "./auth.service";
import Xss from 'xss'
const xss = Xss;

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      const lang = TokenService.getLanguage();
      if(config.data)
        config.data = JSON.parse(xss(JSON.stringify(config.data)));
        
      if (token) {

        if(config.url.indexOf("auth/refresh") >= 0)
          config.headers["Authorization"] = 'Bearer ' + TokenService.getLocalRefreshToken();  // for Spring Boot back-end
        else
          config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        //config.headers["x-access-token"] = token; // for Node.js Express back-end
      }

      if(lang)
        config.headers["x-custom-lang"] = lang;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
//originalConfig.url !== "auth/logout" && 
      // if (originalConfig.url !== "auth/token" && 
      //       originalConfig.url !== "auth/logout" && 
      //       originalConfig.url !== "auth/refresh" && 
      //       originalConfig.url !== "auth/login-participant" && 
      //       err.response) {
      //   // Access Token was expired
      //   if (err.response.status === 401 && !originalConfig._retry) {
      //     originalConfig._retry = true;

      //     try {
      //       const rs = await AuthService.refreshTokenAsync();

      //       if(rs.status == 401){
      //         store.dispatch('auth/logout');
      //         return response.status
      //       }
      //       TokenService.setUser(rs.data);

      //       return axiosInstance(originalConfig);
      //     } catch (_error) {
      //       store.dispatch('auth/logout');
      //       return Promise.reject(_error);
      //     }
      //   }
      // }
      if (err.response?.status === 401)
        store.dispatch('auth/logout');

      return Promise.reject(err);
    }
  );
};

export default setup;