import BannerAproveiteService from '../services/banner.aproveite.service';

export const bannerAproveite = {
  namespaced: true,
  actions: {
    get({ commit }) {
      return BannerAproveiteService.get();
      // return BannerHomeService.get().then(
      //   resp => {
      //     return Promise.resolve(resp);
      //   },
      //   error => {
      //     return Promise.reject(error);
      //   }
      // );
    }
  },
  mutations: {
    }
};
