import LancamentosService from '../services/lancamentos.service';

export const lancamentos = {
  namespaced: true,
  actions: {
    get({ commit }, filters) {
      if(!filters)
        filters = {}
      return LancamentosService.get(filters);
    },

    getMenus({ commit }) {
      return LancamentosService.getCategories();
    }
  },
  mutations: {
  }
};
