import ProductService from '../services/product.service';

export const product = {
  namespaced: true,
  actions: {
    getCategories({ commit }) {
      return ProductService.getCategories().then(
        resp => {
          return Promise.resolve(resp.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    getSubCategories({ commit }, id) {
      return ProductService.getSubCategories(id).then(
        resp => {
          return Promise.resolve(resp.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    getMemberProducts({ commit }, id) {
      return ProductService.getMemberProducts().then(
        resp => {
          return Promise.resolve(resp.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    
    registerProduct({ commit }, product) {
      return ProductService.registerProduct(product).then(
        resp => {
          return Promise.resolve(resp.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    }
};
