import TutoriaisService from '../services/tutoriais.service';

export const tutoriais = {
  namespaced: true,
  actions: {
    get({ commit }, filters) {
      if(!filters)
        filters = {}
      return TutoriaisService.get(filters);
    },

    getMenus({ commit }) {
      return TutoriaisService.getCategories();
    }    
  },
  mutations: {
  }
};
