<script>
import FormInput from '@/components/form/FormInput.vue';
import AppButton from '@/components/ui/AppButton.vue';
import Icons from '@/components/Icons.vue';
export default {
	name: 'Redefinir Senha',
	components: {
		FormInput,
		AppButton,
		Icons
	},
	data() {
		return {
			validToken: false,
			sending: false,
			resetForm: {
				password: '',
				confirmPassword: '',
				token: ''
			},
			showPassword: false,
			showConfirmPassword: false,
			
	
		}
	},
	created() {
		// SEO ======
		document.title = this.$t('meta.entrar.title');
		document.querySelector('meta[name="description"]').setAttribute('content', this.$t('meta.entrar.description'));
		document.querySelector('meta[name="keywords"]').setAttribute('content', this.$t('meta.entrar.keywords'));

		// Definindo as Meta Tags de Open Graph (og:*)
		document.querySelector('meta[property="og:title"]').setAttribute('content', this.$t('meta.entrar.title'));
		document.querySelector('meta[property="og:description"]').setAttribute('content', this.$t('meta.entrar.description'));
		// // Definindo as Meta Tags do Twitter
		document.querySelector('meta[name="twitter:title"]').setAttribute('content', this.$t('meta.entrar.title'));
		document.querySelector('meta[name="twitter:description"]').setAttribute('content', this.$t('meta.entrar.description'));
		// SEO ======

	},
	mounted() {
		if (!this.$route.query?.token)
			this.returnLoginError('Token inválido ou inexistente. Tente novamente!')
		this.verifyToken(this.$route.query.token)
	},
	methods: {
		toggleShowPassword() {
			this.showPassword = !this.showPassword;
		},
		toggleShowConfirmPassword() {
			this.showConfirmPassword = !this.showConfirmPassword;
		},
		sendPassword(event) {
			event.preventDefault();
			this.sending = true;
			if (this.isValid) {
				this.$store.dispatch("auth/resetPasswordToken", this.resetForm).then(
					(resp) => {
						this.$router.push(`/entrar?resetarSenha=true`);
					},
					(error) => {
						if (error?.response?.status == 401)
							this.returnLoginError(this.$t('signUp.tokenError'))
						else
							this.returnLoginError(this.$t('signUp.passError'))
					}
				).catch(error => {
					if (error?.response?.status == 401)
						this.returnLoginError(this.$t('signUp.tokenError'))
					else
						this.returnLoginError(this.$t('signUp.passError'))
				});
			}
		},
		returnLoginError(mensagem) {
			this.$router.push(`/entrar?resetError=true&message=${mensagem}`);
		},
		verifyToken(token) {
			this.$store.dispatch("auth/tokenResetValidation", token).then(
				(resp) => {
					if (resp.status == 200) {
						this.validToken = true
						this.resetForm.token = token
					} else if (resp.status == 401)
						this.returnLoginError(this.$t('signUp.tokenError'))
					else
						this.returnLoginError(this.$t('signUp.tokenError'))
				},
				(error) => {
					if (error?.response?.status == 401)
						this.returnLoginError(this.$t('signUp.tokenError'))
					else
						this.returnLoginError(this.$t('signUp.tokenError'))
				}
			).catch(error => {
				if (error?.response?.status == 401)
					this.returnLoginError(this.$t('signUp.tokenError'))
				else
					this.returnLoginError(this.$t('signUp.tokenError'))
			});
		},
		sequenceValidation(val) {
			const str = val.toLowerCase();
			const codigoA = 'a'.charCodeAt(0);
			const codigoZ = 'z'.charCodeAt(0);
			const codigo0 = '0'.charCodeAt(0);
			const codigo9 = '9'.charCodeAt(0);

			for (let i = 0; i < val.length - 2; i++) {
				const codigoAtual = val.charCodeAt(i);
				const codigoProximo = val.charCodeAt(i + 1);
				const codigoProximo2 = val.charCodeAt(i + 2);

				if (codigoAtual >= codigoA && codigoAtual <= codigoZ &&
					codigoProximo === codigoAtual + 1 &&
					codigoProximo2 === codigoAtual + 2) {
					return true;
				}

				// Verifica sequência numérica
				if (codigoAtual >= codigo0 && codigoAtual <= codigo9 &&
					codigoProximo === codigoAtual + 1 &&
					codigoProximo2 === codigoAtual + 2) {
					return true;
				}
			}
			return false;
		},
	},
	computed: {
		resetPassValidation: function () {
			return {
				password: !!this.resetForm.password,
				passLenght: this.resetForm.password.length >= 8 && this.resetForm.password.length <= 100,
				passCarecteres: !!this.resetForm.password && (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).+$/.test(this.resetForm.password)),
				passEmail: !!this.resetForm.password && this.resetForm.password.indexOf(this.resetForm.email) < 0,
				passSequence: !!this.resetForm.password && !this.sequenceValidation(this.resetForm.password),
				confirmPassword: !!this.resetForm.confirmPassword &&
					(this.resetForm.confirmPassword === this.resetForm.password),
			}
		},
		isValid: function () {
			var ret = true
			Object.entries(this.resetPassValidation).forEach(([key, value]) => {
				if (!value)
					ret = false;
			});
			return ret;
		},
	},
	beforeRouteEnter(to, from, next) {
		window.scrollTo(0, 0);
		next();
	}
}

</script>

<template>
	<section class="wrapper">
		<hgroup>
			<h1>{{ $t('signUp.titlePassRecover') }}</h1>
		</hgroup>
		<form v-if="validToken" class="grid login">
			<div class="row">
				<FormInput :label="$t('form.labels.passwordNew')" inputId="pass" v-model="resetForm.password"
					:inputType="showPassword ? 'text' : 'password'" :placeholder="$t('form.placeholder.passwordNew')"
					:isRequired="true" :isError="sending && !resetPassValidation.password"
					:messageError="$t('form.messageError.passwordNew')">
					<button type="button" class="toggle__password" @click="toggleShowPassword">
						<Icons :name="showPassword ? 'eye' : 'eyeSlash'" />
					</button>
				</FormInput>

				<ul class="pass--valid">
					<li :class="{ 'valid': (resetPassValidation.passLenght) }">
						<Icons name="check" class="ckeckX" />
						<Icons name="xMark" class="xMark" /> <span>{{ $t('form.validPass.01') }}</span>
					</li>
					<li :class="{ 'valid': (resetPassValidation.passCarecteres) }">
						<Icons name="check" class="ckeckX" />
						<Icons name="xMark" class="xMark" /> <span>{{ $t('form.validPass.02') }}</span>
					</li>
					<li :class="{ 'valid': (resetPassValidation.passEmail) }">
						<Icons name="check" class="ckeckX" />
						<Icons name="xMark" class="xMark" /> <span>{{ $t('form.validPass.03') }}</span>
					</li>
					<li :class="{ 'valid': (resetPassValidation.passSequence) }">
						<Icons name="check" class="ckeckX" />
						<Icons name="xMark" class="xMark" /> <span>{{ $t('form.validPass.04') }}</span>
					</li>
				</ul>
				<FormInput :label="$t('form.labels.passwordNewRepeat')" v-model="resetForm.confirmPassword"
					inputId="passRec" :inputType="showConfirmPassword ? 'text' : 'password'"
					:placeholder="$t('form.placeholder.passwordNewRepeat')" :isRequired="true" :col="'col-100'"
					:isError="sending && !resetPassValidation.confirmPassword"
					:messageError="$t('form.messageError.passwordNewRepeat')">
					<button type="button" class="toggle__password" @click="toggleShowConfirmPassword">
						<Icons :name="showConfirmPassword ? 'eye' : 'eyeSlash'" />
					</button>
				</FormInput>
			</div>
			<div class="row buttons--wrap">
				<AppButton @click="sendPassword($event)" variant="primary">{{ $t('buttons.confirmar') }}</AppButton>
			</div>
		</form>
	</section>
</template>

<style lang="sass" scoped>
@use '../assets/sass/utilities/_colors' as *
@use '../assets/sass/utilities/_responsive' as *

.wrapper
	width: min(100%, 550px)
	margin-inline: auto
	padding-inline: 1rem
	margin-block: 4rem
	+respond-to($breakpoint-sm-min)	
		padding-inline: 0	

	hgroup
		margin-bottom: 3.125rem
		h1
			font-weight: 700
			font-size: clamp( 1.40rem, var(--clamp), 2rem)

	.row
		gap: 10px
		&.checkbox
			gap: 10px
			margin-top: 1rem
		
		&.buttons--wrap
			margin-top: 2rem
			justify-content: center



.pass--valid
	margin: 0
	margin-top: -10px
	padding: 0
	list-style: none
	border-radius: 8px
	padding: 1rem
	width: 100%
	background-color: var(--lg-light-gray-300)

	li
		font-size: 11px
		font-weight: 600
		color: var(--lg-red-active)
		display: flex
		gap: 4px
		transition: all .23s ease

		i
			width: 15px
			
			&.xMark
				display: block
			
			&.ckeckX
				display: none

		&.valid
			color: var(--green-10)

			i			

				&.xMark
					display: none
				
				&.ckeckX
					display: block



</style>