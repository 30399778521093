<template>
    <svg viewBox="0 0 54 24" title="Logo LG">
        <path :style="{ fill: lgCircle }" d="M11.85 24c6.539 0 11.85-5.366 11.85-11.997C23.7 5.37 18.388 0 11.85 0 5.3 0 0 5.371 0 12.003 0 18.633 5.3 24 11.85 24z" />
        <path :style="{ fill: lgStroke }" d="M11.373 6.735v10.53h3.303v-.947h-2.351V6.735h-.952zM8.066 9.608A1.43 1.43 0 009.485 8.17c0-.792-.634-1.442-1.42-1.442-.785 0-1.419.65-1.419 1.442a1.43 1.43 0 001.42 1.438z" />
        <path :style="{ fill: lgStroke }" d="M12.324 1.959c-.123-.006-.359-.014-.472-.014-5.469 0-9.92 4.513-9.92 10.053 0 2.69 1.031 5.213 2.9 7.11a9.82 9.82 0 007.02 2.937 9.8 9.8 0 007.007-2.938 10.05 10.05 0 002.91-7.11v-.447h-7.084v.926h6.139c0 .02 0 .118-.004.144-.313 4.736-4.215 8.487-8.968 8.487a8.865 8.865 0 01-6.358-2.67 9.07 9.07 0 01-2.642-6.44 9.11 9.11 0 012.642-6.442 8.876 8.876 0 016.358-2.667c.104 0 .356 0 .472.006V1.96z" />
        <path :style="{ fill: lgType }" d="M32.611 4.04h-3.56v15.623h10.936v-3.041H32.61V4.04zm15.209 9.67h2.9v2.73c-.535.207-1.577.413-2.57.413-3.204 0-4.272-1.65-4.272-5 0-3.196 1.017-5.08 4.221-5.08 1.782 0 2.799.568 3.637 1.65l2.214-2.062c-1.349-1.958-3.715-2.55-5.927-2.55-4.986 0-7.605 2.757-7.605 8.015 0 5.233 2.365 8.068 7.58 8.068 2.39 0 4.731-.618 6.002-1.52v-7.578h-6.18v2.913z" />
    </svg>
</template>

<!-- USE -->
<!-- <LogoLg lgCircle="var(--lg-red)" lgStroke="var(--white)"/> -->


<script>
export default {
    name: 'LogoSVGLG',
    props: {
        lgCircle: {
            type: String,
            default: 'var(--lg-red)'
        },
        lgStroke: {
            type: String,
            default: 'var(--white)'
        },
        lgType: {
            type: String,
            default: 'var(--lg-dark-gray-150)'
        }
    }
}
</script>
	