<template>
    <nav id="nav" >
        <!-- <router-link to="/ofertas-especiais" class="itemNav">Ofertas<br class="brDesk"> Especiais <Icons name="arrowRight"/> </router-link> -->
        <!-- <a href="/#ofertas-especiais" class="itemNav">Ofertas<br class="brDesk"> Especiais <Icons name="arrowRight"/> </a> -->
        <router-link to="/lancamentos" class="itemNav"><span v-html="$t('nav.lancamentos')"></span><Icons name="arrowRight"/></router-link>
        <router-link to="/experiencias-exclusivas" class="itemNav"><span v-html="$t('nav.experiencia')"></span><Icons name="arrowRight"/></router-link>
        <a @click.prevent="openWhatsappModal($event)" href="#" class="itemNav"><span v-html="$t('nav.atendimento')"></span><Icons name="arrowRight"/></a> 
        <router-link to="/dicas-tutoriais" class="itemNav"><span v-html="$t('nav.tutoriais')"></span><Icons name="arrowRight"/></router-link>
    </nav>
</template>

<script>
import Icons from '@/components/Icons.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'nav',
    components: { Icons },
	computed: {
		...mapGetters(['exclusiveServiceModalOpened'])
	},
	methods: {
		...mapActions(['exclusiveServiceModal']),
		openWhatsappModal(event) {
            event.preventDefault();
			this.exclusiveServiceModal()
		}
	}
}
</script>

<style lang="sass" scoped>
@use '../../assets/sass/utilities/_colors' as *
@use '../../assets/sass/utilities/_responsive' as *

#nav
    display: flex
    flex-direction: column
    align-items: flex-start
    margin-top: 3rem
    +respond-to($breakpoint-sm-min)
        gap: 50px
        margin-top: 0
        flex-direction: row
        align-items: center

    .brDesk
        display: none
        +respond-to($breakpoint-sm-min)
            display: block

    .itemNav
        --c: var(--black)

        display: flex
        justify-content: space-between
        
        text-align: center
        align-items: center
        text-decoration: none
        font-size: 1.5rem
        color: var(--c)
        font-weight: 600
        width: 100%
        text-align: left
        padding-block: 20px
        border-bottom: 1px solid var(--black)
        +respond-to($breakpoint-sm-min)
            font-size: 1rem
            padding-block: 0
            border-bottom: none
            text-align: center
            text-wrap: balance

        i
            display: block
            width: 15px
            +respond-to($breakpoint-sm-min)
                display: none
       

        &:hover, &.active
            --c: var(--lg-red-active)

        &:last-child
            border-bottom: none
            padding-bottom: 0

</style>