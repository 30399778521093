<template>
	<span v-for="(tag, index) in tags" :key="index" to="#" class="tag">{{ tag }}</span>
</template>

<script>
export default {
	name: 'Tags',
	props: {
    tags: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="sass" scoped>
@use '../assets/sass/utilities/_colors' as *


.tag
	background-color: var(--white)
	border-radius: 99999px
	border: 1px solid var(--color-grey-100)
	color: var(--black)
	font-size: 14px
	padding: 5px 10px
	transition: all .23s ease
	text-decoration: none

	&::before
		content: '#'


</style>