import TagsService from '../services/tags.service';

export const tags = {
  namespaced: true,
  actions: {
    get({}, filters = {}) {
      return TagsService.get(filters)
    }
  }
};
