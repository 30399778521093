import api from "./api";

const banners = `mocks${import.meta.env.VITE_BASE_PATH}banners.aproveite.json`
var jsonFinal = "" 

class BannerAproveiteService {
  async get() {
    if(!jsonFinal){
      const resp = await fetch(banners)
      jsonFinal = await resp.json()
    }
    return jsonFinal;
  }
}

export default new BannerAproveiteService();
