<script>
import Icons from '@/components/Icons.vue';
import AppButton from '@/components/ui/AppButton.vue';
export default {
	name: 'BoxProd',
	props: {
		name: String,
		category: String,
		subCategory: String
	},
	components: {
		Icons, AppButton
	},

}
</script>
<template>
	<div>
		<div class="box--product">
			<div class="box--product__content">
				<div class="box--product__header">
					<Icons name="userIcon" />
					<p>{{ $t('form.selectProd.categoria') }}</p>
				</div>
				<span>{{category}}</span>
			</div>
			<div class="box--product__content">
				<div class="box--product__header">
					<Icons name="catProd" />
					<p>{{ $t('form.selectProd.subCategoria') }}</p>
				</div>
				<span>{{subCategory}}</span>
			</div>
			<div class="box--product__content">
				<div class="box--product__header">
					<Icons name="prodIcon" />
					<p>{{ $t('form.selectProd.produto') }}</p>
				</div>
				<span>{{name}}</span>
			</div>
			
		</div>
	</div>
</template>


<style lang="sass" scoped>
@use '../assets/sass/utilities/_colors' as *
@use '../assets/sass/utilities/_responsive' as *
@use '../assets/sass/utilities/_mixin' as *

.box--product
	background-color: var(--white)
	border-radius: 1.5rem
	padding: 1.3rem
	width: 100%
	height: 100%
	
	button
		margin-top: 24px
		margin-inline: auto

	.box--product__content
		--p: 10px
		border-bottom: 1px solid var(--color-grey-1700)
		margin-bottom: var(--p)
		padding-bottom: var(--p)


		&:last-child
			border-bottom: 0
			margin-bottom:  0
			padding-bottom:  0
			

		.box--product__header
			display: flex
			flex-direction: row
			gap: 10px
			font-size: 20px
			margin-bottom: 10px
		

			i
				width: 25px
				display: block

			p
				font-weight: 600

</style>