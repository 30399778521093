<template>
	<template v-if="showIf">
		<button @click="$emit('interceptAccess', link)" v-if="!link" :class="buttonClass" >
			<Icons :name="iconName" v-if="showIcon"/>
			<span>{{ buttonText }}</span>
		</button>

		<a @click="emitClick(link)" v-else :class="buttonClass">
			<Icons :name="iconName" v-if="showIcon"/>
			<span>{{ buttonText }}</span>
		</a>
	</template>
</template>

<!-- Usage -->
<!-- <Button type="primary" buttonText="Entrar" small outline showIcon icon="plus" reverse/> -->
<!-- <Button type="primary" buttonText="Ir para o Link" showIcon icon="plus" link="https://example.com"/> -->

<script>
import Icons from '@/components/Icons.vue'

export default {
  props: {
    icon: String,
    tooltip: String,
    type: String,
    buttonText: String,
    showIcon: Boolean,
    link: String, 
	reverse: Boolean,
	small: Boolean,
	outline: Boolean,
	noback: Boolean,
	showIf: {type: Boolean, default: true},
	isTabSelf: Boolean
  },
  computed: {
    buttonClass() {
      return {
        'buttonApp': true,
        [`btn-${this.type}`]: this.type,
		'flex-reverse': this.reverse,
		'small': this.small,
		'outline': this.outline,
		'noback': this.noback,
      };
    },
    iconName() {
      return this.icon;
    },
	login : function(){
		return {
			name: this.$store.state.auth.user?.name,
			logged: !!this.$store.state.auth.user
		}
	}
  },
  methods: {
    emitClick(to) {
		if(this.login.logged)
			if(to.indexOf('lgfamilyclub.com') < 0 && to.indexOf('/') !== 0){
				if(to.indexOf('https://')<0)
					to='https://'+to
				window.open(to, (this.isTabSelf?'_self':'_blank'))
			}else{
				if(to.indexOf(import.meta.env.VITE_BASE_PATH) >= 0)
					to = to.substring(to.indexOf(import.meta.env.VITE_BASE_PATH)+3)
				this.$router.push(to)
			}
		else if (['/entrar', '/cadastre-se'].includes(to))
			this.$router.push(to)
		else
			this.$emit('unknown')
    },
  },
  components: {
    Icons,
  },
};
</script>



<style lang="sass" scoped>
@use '../../assets/sass/utilities/_colors' as *
@use '../../assets/sass/utilities/_responsive' as *

.buttonApp
	--gap: 7px
	--pl: 30px
	--p: 12px
	--icon: 1rem
	--br: 0.0938rem
	
	border: 0	
	border-radius: 9999px
	padding: var(--p) var(--pl)
	display: flex
	flex-direction: row
	align-items: center	
	justify-content: center
	font-weight: 600
	font-size: var(--f-s)
	transition: all .23s ease-in-out
	text-decoration: none
	width: fit-content
	height: fit-content
	color: var(--color)
	background: var(--color-bg)
	user-select: none
	border: var(--br) solid transparent
	cursor: pointer
	font-family: var(--lgei-headline)

	&.outline
		background: none
		border: var(--br)m solid currentcolor

		&:hover
			background: var(--color)
			color: var(--white)
			opacity: 1
	
	&.flex-reverse
		flex-direction: row-reverse

	&.small
		--f-s: 0.875rem
		--p: 8px
		--pl: 20px

	&:has(i)
		gap: var(--gap)
		padding: var(--p) calc(var(--pl) - var(--gap))

	i
		width: var(--icon)


.btn-primary
	--color: var(--white)
	--color-bg: var(--lg-red-active)

	&:hover
		--color: var(--black)
		--color-bg: var(--white)
		border: var(--br) solid currentcolor
	
	&.outline
		--color: var(--lg-red-active)
		

.btn-secondary
	--color: var(--black)
	--color-bg: var(--white)
	border: 1px solid currentcolor

	&:hover
		border: var(--br) solid var(--lg-red-active)
		--color-bg: var(--lg-red-active)
		--color: var(--white)	

	
	&.outline
		--color: var(--color-grey-1000)
			

.btn-tertiary
	--color: var(--white)
	--color-bg: var(--black)	

	&:hover
		--color-bg: none
		border: var(--br) solid var(--black)

	
	&.outline
		--color: var(--color-grey-1000)
			

</style>
	