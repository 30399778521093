<script>
import Icons from '@/components/Icons.vue'
export default {
	name: "AppButton",
	components: {
		Icons,
	},
	props: {
		variant: {
			type: String,
			default: "default",
		},
		icon: String,
		to: String,
		href: String,
		type: {
			type: String,
			default: "button",
		},
		reverse: Boolean,
		small: Boolean,
		showIcon: {
			type: Boolean,
			default: true
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		isExternalLink() {
			return this.href && !this.href.startsWith('/');
		},
		isInternalLink() {
			return this.to && this.to.startsWith('/');
		},
		isButton() {
			return !this.to && !this.href;
		},
		iconName() {
			return this.icon;
		},
		btnClass() {
			return [
				'AppButton',
				{ 'small': this.small },
				{ 'flex-reverse': this.reverse },
				`AppButton__${this.variant}`,
				{ 'disabled': this.disabled }
			];
		}
	}
};
</script>

<template>
	<component
		:is="isButton ? 'button' : isExternalLink ? 'a' : 'router-link'"
		:to="isInternalLink ? to : null"
		:type="isButton ? type : null"
		:href="isExternalLink ? href : null"
		:class="btnClass"
		:disabled="disabled"
	>
		<Icons :name="iconName" v-if="showIcon" />
		<span>
			<slot></slot>
		</span>
	</component>
</template>

<!-- Options ===
<AppButton variant="primary" to="/teste" icon="addNew">Nova Campanha</AppButton>
<AppButton variant="primary">Nova Campanha</AppButton>
<AppButton variant="primary" icon="addNew">Nova Campanha</AppButton>
<AppButton variant="secondary">Label botão</AppButton>
 -->

<style lang="sass" scoped>
@use '../../assets/sass/utilities/_colors' as *

.AppButton
	--f-s: 1
	--p: 0.875rem
	--pl: 1.875rem

	min-width: 6.25rem
	justify-content: center
	letter-spacing: -.025em
	padding: var(--p) var(--pl)
	border-radius: 99999px
	cursor: pointer !important
	font-weight: 600 !important
	display: flex
	flex-direction: row
	align-items: center
	gap: 0.375rem	
	font-size: var(--f-s)
	white-space: nowrap
	background-color: var(--bgColor)
	color: var(--textCoolor)
	transition: all 0.23s ease
	text-decoration: none
	border: .0625rem solid var(--bdColor)
	user-select: none

	&:disabled, &.disabled
		--bgColor: var(--color-grey-700)
		--textCoolor: var(--white)
		--bdColor: transparent
		cursor: not-allowed !important

		&:hover
			--bgColor: var(--color-grey-700)
			--textCoolor: var(--white)
			--bdColor: transparent

	&.small
		min-width: fit-content !important
		--f-s: 0.875rem
		--p: 8px
		--pl: 20px

	&.flex-reverse
		flex-direction: row-reverse

	&:has(span:empty)
		gap: 0
		padding: 10px
		border-radius: 50%	

	i
		width: 18px 	

	&__primary
		--bgColor: var(--lg-red-active)
		--textCoolor: var(--white)
		--bdColor: transparent

		&:hover, &.active
			--bgColor: var(--white)
			--textCoolor: var(--lg-dark-gray-200)
			--bdColor: currentcolor
		

	&__secondary
		--bgColor: var(--white)
		--textCoolor: var(--lg-dark-gray-200)
		--bdColor: currentcolor

		&:hover, &.active
			--bgColor: var(--lg-red-active)
			--textCoolor: var(--white)
			--bdColor: transparent

	&__black
		--bgColor: var(--black)
		--textCoolor: var(--white)
		--bdColor: currentcolor

		&:hover, &.active
			--bgColor: var(--lg-red-active)
			--textCoolor: var(--white)
			--bdColor: transparent
</style>