<template>
	<div :class="['box--form', col, { error: isError }]">
		<label :for="inputId" :class="{ required: isRequired }">{{ label }}</label>
		<div class="input-wrapper">
			<!-- O select encapsula as opções corretamente -->
			<select :id="inputId" :name="inputId" :disabled="isDisabled" :value="modelValue"
				@change="$emit('update:modelValue', $event.target.value)">
				<option disabled selected value="">{{ placeholder }}</option>
				<slot></slot> <!-- As opções serão injetadas aqui -->
			</select>
		</div>
		<span v-show="isError" class="alertErro">
			<Icons name="exclamation" /> {{ messageError }}
		</span>
	</div>
</template>

<script>
import Icons from '@/components/Icons.vue';
export default {
	components: {
		Icons
	},
	props: {
		modelValue: [String, Number],
		label: { type: String, required: true },
		inputId: { type: String, required: true },
		col: { type: String, default: "" },
		isRequired: { type: Boolean, default: false },
		isError: { type: Boolean, default: false },
		isDisabled: { type: Boolean, default: false },
		messageError: { type: String, default: "" },
		placeholder: { type: String, default: "Select an option" },
	},
	emits: ["update:modelValue"],
};
</script>

<style lang="sass" scoped>
@use '../../assets/sass/utilities/_colors' as *
@use '../../assets/sass/utilities/_responsive' as *


.input-wrapper
	position: relative

.box--form
	display: flex
	flex-direction: column
	width: 100%
	gap: 6px

	label
		font-weight: 500
		font-size: .85rem
		color: var(--magenta--title-default)

	
		&.required
			&::after
				content: '*'
				display: inline-block
				color: var(--lg-red-active)
				font-size: 12px
				top: 0
				margin-left: 1px
				vertical-align: text-top



	select
		width: 100%
		height: 2.5rem
		border-radius: 8px
		padding: 5px
		transition: all .20s ease-in-out
		border: 1px solid var(--color-grey-700)
		font-size: .85rem
		display: block
		-webkit-appearance: textfield
		-moz-appearance: textfield
		-webkit-appearance: none
		-moz-appearance: none
		appearance: none
		color: var(--black)
		background: var(--white) url("data:image/svg+xml,%3Csvg data-slot='icon' fill='none' stroke-width='1.7' stroke='gray' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5'%3E%3C/path%3E%3C/svg%3E") no-repeat calc(100% - 11px) / 16px auto

		&:hover
			border: 1px solid var(--lg-red)

		&:focus
			border: 1px solid var(--lg-red)
			box-shadow: 0px 0px 0px 4px rgba(var(--lg-red-rgb), .11 )

		&:disabled
			cursor: not-allowed
			background: var(--color-grey-100)
			&:hover
		
				border: 1px solid var(--color-grey-1000)		

		&::placeholder, &:invalid
			color: var(--lg-mid-gray-200) 

			
	&:where(.error)
		.alertErro
			display: flex

		select
			border: 1px solid var(--lg-red-active)
			color: var(--lg-red-active)
			background: rgba(var(--lg-red-active-rgb), .035) url("data:image/svg+xml,%3Csvg data-slot='icon' fill='none' stroke-width='1.7' stroke='red' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5'%3E%3C/path%3E%3C/svg%3E") no-repeat calc(100% - 11px) / 16px auto

			&::placeholder
				color: var(--lg-red-active)

.alertErro
	display: none
	user-select: none
	font-size: 0.75rem
	width: fit-content
	background: rgba(var(--lg-red-rgb), .085)
	padding: 5px 10px
	color: var(--lg-red-active)
	border-radius: 6px
	font-weight: 600
	align-items: center
	gap: 6px

	i
		display: flex
		width: 1rem

		svg
			width: 100%

</style>