<script>
export default {
	props: {
		id: {
			type: String,
			required: true,
		},
		name: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		checked: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		inputId() {
			return `${this.id}`;
		},
		wrapperClass() {
			return `radio-wrapper-${this.id}`;
		},
		isDisabled() {
			return this.disabled;
		},
		isChecked() {
			return this.checked;
		},
	},
};
</script>

<template>
	<div class="radio-wrapper">
		<input :id="inputId" :value="inputId" name="radio" type="radio" :checked="isChecked" :disabled="isDisabled" aria-hidden="true">
		<label :for="inputId">
			<slot></slot>
		</label>
	</div>
</template>



<style lang="sass" scoped>
@use '../../assets/sass/utilities/_colors' as *

.radio-wrapper 
	height: fit-content
	display: flex
	align-items: center
	justify-content: center

@supports (-webkit-appearance: none) or (-moz-appearance: none)
	.radio-wrapper input[type=radio]
		--size-input: 18px
		--active: var(--lg-red-active)
		--active-inner: var(--white)
		--focus: 2px rgba(var(--lg-red-active-rgb), 0.3)
		--border: var(--color-grey-100)
		--border-hover: var(--lg-red-active)
		--background: var(--white)
		--disabled: var( --color-grey-100)
		--disabled-inner: var( --color-grey-200)

		-webkit-appearance: none
		-moz-appearance: none
		height: var(--size-input)
		
		outline: none
		display: inline-block
		vertical-align: top
		position: relative
		margin: 0
		cursor: pointer
		border: 1px solid var(--bc, var(--border))
		background: var(--b, var(--background))
		transition: background 0.3s, border-color 0.3s, box-shadow 0.2s

		&:after
			content: ""
			display: block
			left: 0
			top: 0
			position: absolute
			transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s)

		&:checked
			--b:var(--active)
			--bc:var(--active)
			--d-o:.3s
			--d-t:.6s
			--d-t-e:cubic-bezier(0.2, 0.85, 0.32, 1.2)

		&:disabled
			--b:var(--disabled)
			cursor: not-allowed
			opacity: 0.9

			&:checked
				--b:var(--disabled-inner)
				--bc:var(--border)

			+ label
				cursor: not-allowed

		&:hover:not(:checked):not(:disabled)
			--bc:var(--border-hover)

		&:focus
			box-shadow: 0 0 0 var(--focus)

		&:not(.switch)
			width: var(--size-input)

			&:after
				opacity: var(--o, 0)

			&:checked
				--o:1

		border-radius: 50%

		&:after
			width: calc( var(--size-input) / 3 )
			height: calc( var(--size-input) / 3 )
			background: var(--white)
			border-radius: 50%
			top: 5px
			left: 5px
			

		&:checked
			--r:43deg

		+ label
			display: inline-block
			vertical-align: middle
			cursor: pointer
			margin-left: 0.625rem

.radio-wrapper *
	box-sizing: inherit

	&:before, &:after
		box-sizing: inherit


</style>





