<template>
	<section class="trend--topcs">
		<h3>{{ $t('pageTutoriais.trendTitle') }}</h3>
		<div class="trend--topcs__content">
			<article class="cardTrend" v-for="(trend, index) in displayedTrends" :key="trend.id" :trend="trend" @click="$emit('activeTrending', trend.id)">
				<figure>
					<img :src="getImage(trend.tutorialFiles)" :alt="trend.content.title" :title="trend.content.title">
				</figure>
				<p>{{ trend.content.title }}</p>
			</article>
		</div>
		<div class="content--btnMore">
			<button class="loadMoreBtn" v-if="showLoadMoreBtn" @click="loadMoreTrend">{{ $t('buttons.showMore') }}<Icons name="arrowDown" /></button>
		</div>
	</section>
</template>

<script>
import Icons from '@/components/Icons.vue';
export default {
	name: 'Trends',
	props: {
		listTrendings: {type: Object, default: []}
	},
	components: {
		Icons,
		
	},

	data() {
		return {
			trends: [],
			displayedTrends: [],
			trendPerLoad: this.isMobile() ? 2 : 6,
			totalTrendVisible: this.isMobile() ? 4 : 6,
			totalTrendVisible: this.isMobile() ? 4 : 6,
			showLoadMoreBtn: true,
		};
	},
	mounted() {
		if(this.listTrendings)
			this.trends = this.listTrendings

		this.loadMoreTrend()
	},

	methods: {
		getImage(files){
			if(!files || files.length <= 0)
				return ''
			let fl = files.find(el => el.tutorialFileTypeId == 1)

			return import.meta.env.VITE_APP_API_URL+'files/render/'+fl.file.name
		},
		isMobile() {
			return window.innerWidth <= 768;
		},
		loadMoreTrend() {
			this.displayedTrends = this.trends.slice(0, this.totalTrendVisible);
			this.totalTrendVisible += this.trendPerLoad;
			if (this.displayedTrends.length >= this.trends.length) {
				this.showLoadMoreBtn = false;
			}
		}
	}
}
</script>

<style lang="sass" scoped>
@use '../assets/sass/utilities/_colors' as *
@use '../assets/sass/utilities/_responsive' as *
@use '../assets/sass/utilities/_mixin' as *

.trend--topcs
	--sizeCard: 120px

	padding-block: 2rem
	+respond-to($breakpoint-sm-min)
		--sizeCard: 190px

	h3
		font-weight: 700
		@include font-size (25px, 30px)
		margin: 0 0 2rem var(--pad)


	&__content
		padding: 0  var(--pad)
		display: grid
		grid-template-columns: repeat(auto-fill, minmax(var(--sizeCard), 1fr))
		grid-auto-flow: row
		gap: 1rem

	.cardTrend
		cursor: pointer
		width: 100%

		figure
			aspect-ratio: 9 / 16
			overflow: hidden
			border-radius: 10px
			background-color: var(--black)
			transition: all .23s ease
			display: flex
			justify-content: center

			img
				height: 100%
				max-width: inherit
				transition: all .23s ease

		p
			margin-top: 5px
			padding-inline: 10px
			line-height: 1.1
			font-weight: 700
			@include line-limited (2)


		&.active, &:hover		
			figure
				border-bottom: 6px solid var(--lg-red-active)	
				img
					opacity: .3

</style>