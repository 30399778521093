<template>
	<footer id="footer" >
		<div id="footer--content">
			<nav class="nav--footer">
				<!-- LINK NEW PAGE -->
				<router-link :to="$t('footer.termsOfUse.url')" class="link--country">{{ $t('footer.termsOfUse.label') }}</router-link>
				<router-link :to="$t('footer.personalData.url')" class="link--country"  v-if="aux === '/ec/'">{{ $t('footer.personalData.label') }}</router-link>
				<router-link :to="$t('footer.privacyPolicy.url')" class="link--country">{{ $t('footer.privacyPolicy.label') }}</router-link>
				<router-link :to="$t('footer.privacyCookies.url')" class="link--country">{{ $t('footer.privacyCookies.label') }}</router-link>
				
				<!-- <router-link :to="$t('footer.lgpd.url')" class="link--country">{{ $t('footer.lgpd.label') }}</router-link> -->
				<a :href="$t('footer.lgpd.url')" class="link--country" v-if="aux === '/br/' || aux === '/pe/'">{{ $t('footer.lgpd.label') }}</a>

				<router-link :to="$t('footer.legal.url')" class="link--country">{{ $t('footer.legal.label') }}</router-link>
				<router-link :to="$t('footer.accessibility.url')" class="link--country">{{ $t('footer.accessibility.label') }}</router-link>

			</nav>

			<div class="footer--copy">
				<p>Copyright © 2009-{{ currentYear }} {{ $t('footer.reserved') }}</p>
				<a :href="$t('footer.lgcorp.url')" target="_blank">{{ $t('footer.lgcorp.text') }}</a>
				<br>
				<p v-for="(linha, index) in linhasAddress" :key="linhasAddress.length">{{linha}}</p>
			</div>

			<section class="footer--social">

				<!-- ITEM APENAS PARA O PERU ==================================== -->
                <div class="link--libro" v-if="aux === '/pe/'">
					<img :src="'/pe/src/assets/img/reclamaciones-icon.png'" alt="Libro de Reclamaciones" title="Libro de Reclamaciones">
				 	<a href="https://www.lg.com/pe/soporte/libro-de-reclamaciones/" target="_blank" class="Libro de Reclamaciones" title="Libro de Reclamaciones">Libro de Reclamaciones</a> 
				</div>
				<!-- ITEM APENAS PARA O PERU ==================================== -->

				<div class="social--links">
					<a v-for="(media, index) in socialMedias" :href="media.url" target="_blank" :class="getClass(media.socialMediaTypeId)" :title="getTitle(media.socialMediaTypeId)">
						<Icons :name="getIconName(media.socialMediaTypeId)" />
					</a>
				</div>
			</section>
		</div>
	</footer>
</template>

<script>
import LogoLg from '@/components/template/LogoLg.vue'
import Icons from '@/components/Icons.vue'
export default {
	name: 'Footer',
	components: {
		LogoLg,
		Icons
	},
	props: {
		socialMedias: Object,
		country: Object
	},
	data() {
		return {
			currentYear: new Date().getFullYear(),
			aux: import.meta.env.VITE_BASE_PATH
		};
	},
	created(){
		console.log(this.$t('footer.social.tiktok'))

	},
	computed: {
		linhasAddress(){
			if(!this.country?.address)
				return []
			var linhasAux = this.country.address.split('\n')
			
			if(!linhasAux || linhasAux.length <= 0)
				linhasAux = [this.country.address]

			return linhasAux
		}
	},
	methods: {
		changeLanguage(event) {
			this.$i18n.locale = event.target.value;
		},
		changeArroba(url){
			return url.replace('%ARR%', '@')
		},
		getIconName(typeId){
			switch(typeId){
				case 1: return 'tiktok';
				case 2: return 'facebook';
				case 3: return 'instagram';
				case 4: return 'youtube';
				case 5: return 'ex';
				default: return 'ex';
			}
		},
		getTitle(typeId){
			switch(typeId){
				case 1: return 'tiktok';
				case 2: return 'Facebook';
				case 3: return 'Instagram';
				case 4: return 'YouTube';
				case 5: return 'Ex';
				default: return 'Ex';
			}
		},
		getClass(typeId){
			switch(typeId){
				case 1: return 'tiktok';
				case 2: return 'facebook';
				case 3: return 'instagram';
				case 4: return 'youtube';
				case 5: return '';
				default: return '';
			}
		}
	}

}
</script>


<style lang="sass" scoped>
@use '../../assets/sass/utilities/_colors' as *
@use '../../assets/sass/utilities/_responsive' as *
.comboLocal
	
	margin-block: 1rem
	max-width: 120px
	select
		width: 100%
		border-radius: 4px

#footer
	background-color: var(--lg-dark-gray-100)
	width: 100%
	padding-block: 2.4rem
	padding-inline: 1rem
	+respond-to($breakpoint-xxl-min)
		padding-inline: 0
	

	#footer--content
		width: min(100%, var(--contentSize))
		margin-inline: auto
		display: flex
		flex-direction: column
		justify-content: space-between
		
		@container layout (width <= 1680px)
			padding-inline: 2rem

		.nav--footer
			display: flex
			row-gap: 20px
			column-gap: 50px
			flex-wrap: wrap

			a
				color: var(--white)
				text-decoration: none
				font-size: 1rem

				&:hover
					text-decoration: underline

		.footer--copy
			margin-top: 20px
			display: flex
			flex-direction: column
			gap: 7px

			a, p
				width: fit-content
				color: var(--color-grey-300)
				
		.footer--social
			margin-top: 2rem
			display: flex
			flex-direction: row
			flex-wrap: wrap
			row-gap: 20px
			justify-content: flex-end

			&:has(.link--libro)
				justify-content: space-between

			.link--country
				display: flex
				flex-direction: row
				
				align-items: center
				gap: 7px
				color: var(--white)

			.link--libro
				display: flex
				flex-direction: row
				padding: 1rem
				border-radius: .5rem
				align-items: center
				gap: 7px
				background-color: var(--white)

				a
					color: var(--black)
					text-decoration: none
					font-size: 1rem

					&:hover
						text-decoration: underline

			.social--links
				display: flex
				justify-content: flex-end
				align-items: center
				gap: 20px

				a
					display: flex
					justify-content: center
					align-items: center
					width: 40px
					height: 40px
					border-radius: 50%
					padding: 9px
					background-color: var(--white)
					color: var(--black)	
					transition: all 0.23s ease

					&.facebook
						&:hover
							color: #0765FF

					&.youtube
						&:hover
							color: #ff0000
					
					&.instagram
						&:hover
							color: #F20084

	
</style>