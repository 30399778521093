import api from "./api";

class LancamentosService {
  get(filters){
    var params = '?';
    if(filters.limit && filters.limit !== null && filters.limit !== '') params+='limit='+filters.limit+'&'
    if(filters.offset && filters.offset !== null && filters.offset !== '') params+='offset='+filters.offset+'&'
    if(filters.search && filters.search !== null && filters.search !== '') params+='search='+filters.search+'&'
    if(filters.productLaunchCategoryId && filters.productLaunchCategoryId !== null && filters.productLaunchCategoryId !== '') params+='productLaunchCategoryId='+filters.productLaunchCategoryId+'&'

    return api.get(`product-launches${params}`);
  }

  getCategories(){
    return api.get(`product-launches/product-launch-categories`);
  }
}

export default new LancamentosService();
