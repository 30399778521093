<template>
	
	<header id="header" ref="header">
		<!-- Menu mobile ===== -->
		<Transition>
			<div class="content--nav--mobile" v-if="isNavMobile" @click="closeMenuMobile"><Nav /></div>
		</Transition>
		<!-- Menu mobile ===== -->
		
		<div id="header--content">
			<div class="content--left">
				<router-link to="/" class="logoLG"><LogoLg @click="closeMenuMobile"/></router-link>
				<div class="content--nav"><Nav /></div>
			</div>
			<div class="content--right">
				<router-link to="/" class="logoClub"><LogoClub logoSymbol="var(--lg-red-active)" /></router-link>
				<div class="content--btns">
					<router-link to="/buscar" class="btnSearch"><Icons name="search"/></router-link>

					<!-- OCULTAR BOTAO APOS LOGADO ================= -->
					<button v-if="!login.logged" class="btnUser" @click="toggleMenu" ><Icons name="user" /></button>

					<!-- EXIBIR BOTAO APOS LOGADO ================= -->
					<button v-if="login.logged" class="btnUserLogin" @click="toggleMenuUser" ><span>{{getNameIcon(login.name)}}</span></button>

					<div class="mobileIcon--nav" @click="toggleMenuMobile" :class="{ active: isNavMobile }"><span></span><span></span><span></span></div>
				</div>

				<Transition>
					<div class="navEnter" v-if="isEnter" @click="closeMenuMobile">
						<router-link to="/entrar" class="linkEnter">{{ $t('header.menuEntrar') }}</router-link>
					</div>
				</Transition>

				<Transition>
					<!-- EXIBIR BOTAO APOS LOGADO ================= -->
					<div class="navUser" v-if="isNavUser" @click="closeMenuMobile">
						<router-link to="/minha-area" class="linkEnter">{{ $t('header.menuArea') }}</router-link>
						<router-link @click="logout()" to="/" class="linkEnter">{{ $t('header.menuSair') }}</router-link>
					</div>
				</Transition>
			</div>
		</div>
	</header>
</template>

<script>
import Icons from '@/components/Icons.vue';
import LogoLg from '@/components/template/LogoLg.vue';
import LogoClub from '@/components/template/LogoClub.vue';
import Nav from '@/components/template/Nav.vue';
export default {
	components: { LogoLg, LogoClub, Nav, Icons },
	data() {
    return {
		isEnter: false,
		isNavUser: false,
		isNavMobile: false
    };
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  computed:{
	login : function(){
		return {
			name: this.$store.state.auth.user?.name,
			logged: !!this.$store.state.auth.user
		}
	}
  },
  methods: {
	logout(){
		this.$store.dispatch('auth/logout').then(() =>{
			this.$router.push("/entrar");
		});
	},
	getNameIcon(name){
		let iniciais = name[0]
		if(name.indexOf(' ') >= 0)
			iniciais+=name[name.indexOf(' ')+1]
		return iniciais
	},
    handleClickOutside(event) {
      const header = this.$refs.header;
      if (header && !header.contains(event.target)) {
        this.isEnter = false;
        this.isNavUser = false;
        this.isNavMobile = false;
      }
    },
	toggleMenu() {
      this.isEnter = !this.isEnter;
    },
	toggleMenuUser() {
      this.isNavUser = !this.isNavUser;
    },
	toggleMenuMobile() {
      this.isNavMobile = !this.isNavMobile;
    },
	closeMenuMobile() {
      this.isNavMobile = false;
    }
  }
}
</script>

<style lang="sass" scoped>
@use '../../assets/sass/utilities/_colors' as *
@use '../../assets/sass/utilities/_responsive' as *
@use '../../assets/sass/utilities/_mixin' as *

#header
	width: 100%
	padding-block: 20px
	border-bottom: 2px solid var(--lg-light-gray-300)	

	&--content
		display: flex
		justify-content: space-between
		margin-inline: auto
		width: min(100%, var(--contentSize)) 
		padding-inline: var(--mobile-p)
		+respond-to($breakpoint-sm-min)
			padding-inline: 2rem

		

		.content--left
			display: flex
			align-items: center
			gap: 48px
			.logoLG
				display: block
				max-width: 60px
				+respond-to($breakpoint-sm-min)
					max-width: 80px

			.content--nav
				display: none
				+respond-to($breakpoint-sm-min)
					display: block

		.content--right
			display: flex
			align-items: center
			gap: 2.8125rem
			position: relative

			.logoClub
				max-width: 120px
				+respond-to($breakpoint-sm-min)
					max-width: 160px

			.content--btns
				display: flex
				align-items: center
				gap: 15px

				.btnSearch
					background: none
					display: block
					width: 21px
					color: var(--black)
					&:hover
						color: var(--lg-red-active)

				.btnUser
					cursor: pointer
					background: none
					display: block
					width: 27px
					color: var(--black)

					&:hover
						color: var(--lg-red-active)

				.btnUserLogin
					cursor: pointer
					background: var(--lg-red-active)
					display: block
					width: 30px
					height: 30px
					display: flex
					align-items: center
					justify-content: center
					border-radius: 50%
					color: var(--white)
					font-size: 13px
					font-weight: 600

					&:hover
						background: var(--lg-red-active)


			.navEnter, .navUser
				--p: 20px 

				display: flex
				width: 100%
				flex-direction: column
				gap: var(--p)
				border-radius: 0 0 10px 10px
				z-index: 100
				top: 49px
				padding-block: 20px
				background-color: var(--lg-light-gray-200)
				right: 0
				position: absolute
				border: 1px solid var(--lg-light-gray-300)
				box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px
				+respond-to($breakpoint-sm-min)
					top: 57px

				a
					color: var(--black)
					font-size: 1.1rem
					text-decoration: none
					font-weight: 600
					display: block
					padding-inline: 20px
					padding-bottom: var(--p)
					border-bottom: 1px solid var(--lg-mid-gray-100)
					
					&:hover
						color: var(--lg-red-active)

					&:last-child
						border-bottom: none
						padding-bottom: 0
						

.content--nav--mobile
	top: 69px
	background-color: var(--lg-light-gray-200)
	position: absolute
	width: 100%
	height: 100vh
	z-index: 20
	padding-inline: var(--mobile-p)
	+respond-to($breakpoint-sm-min)
		display: none					

.mobileIcon--nav
	--s: 25px
	--animationNav: 7px

	width: var(--s)
	height: var(--s)
	display: flex
	flex-direction: column
	align-items: flex-end
	justify-content: center
	gap: calc( var(--animationNav) - 2px  )
	overflow: hidden

	span
		width: 100%
		height: 2px
		background-color: var(--black)
		transition: all .23s cubic-bezier(.77,0,.175,1)

		&:nth-of-type(2)
			width: 80%

	&.active
		span
			&:nth-of-type(1)
				rotate: 45deg
				translate: 0 var(--animationNav)

			&:nth-of-type(2)
				translate:  calc( var(--s) * -1  ) 0
			

			&:nth-of-type(3)
				rotate: -45deg
				translate: 0 calc( var(--animationNav) * -1  ) 0
	

			
	+respond-to($breakpoint-sm-min)
		display: none


.v-enter-active, .v-leave-active 
	transition: opacity 0.2s ease

.v-enter-from, .v-leave-to
	opacity: 0

</style>