import api from "./api";

const banners = `mocks${import.meta.env.VITE_BASE_PATH}faq.json`
var jsonFinal = "" 
class FaqService {
  async get() {
    if(!jsonFinal){
      const resp = await fetch(banners)
      jsonFinal = await resp.json();
    }
    return jsonFinal
  }
}

export default new FaqService();
