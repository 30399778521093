<template>
<svg fill="none" viewBox="0 0 905 100">
    <path :style="{ fill: logoSymbol }" d="M671.598 64.57c-4.23 21.009-22.137 34.545-44.556 34.545-27.354 0-47.517-20.163-47.517-49.632 0-29.187 20.163-49.35 47.517-49.35 22.419 0 40.326 13.536 44.556 34.263h-18.189c-2.538-12.972-11.139-17.625-26.367-17.625-20.022 0-28.623 8.178-28.623 32.712 0 24.816 8.601 32.994 28.623 32.994 15.228 0 23.829-4.794 26.367-17.907h18.189zM679.371 97V2.248h19.035v78.114h44.838V97h-63.873zM765.931 64.57c0 11.703 4.653 17.907 19.458 17.907s19.458-6.204 19.458-17.907V2.248h19.035V60.34c0 23.829-14.946 38.775-38.493 38.775-23.547 0-38.493-14.946-38.493-38.775V2.248h19.035V64.57zM834.421 97V2.248h31.302c19.599 0 35.109 8.037 35.109 25.521 0 10.434-5.781 16.779-14.664 20.163 10.857 3.243 18.189 10.293 18.189 23.547 0 17.484-16.638 25.521-35.532 25.521h-34.404zm18.612-16.638h20.727c7.332 0 10.998-2.397 10.998-11.703s-3.666-11.562-10.998-11.562h-20.727v23.265zm0-39.057h17.625c7.332 0 10.998-2.82 10.998-11.421 0-8.601-3.666-10.998-10.998-10.998h-17.625v22.419zM.05 97V2.248h19.035v78.114h44.838V97H.05zM134.479 32.986c-3.243-12.267-9.729-16.215-25.098-16.215-19.317 0-28.623 8.883-28.623 32.712 0 24.111 9.306 32.994 28.623 32.994 16.92 0 23.124-7.473 25.098-11.985.846-1.974.987-3.666.987-5.781v-4.653h-27.495V44.689h44.556v20.586c0 3.102-.282 6.063-1.41 9.306-3.243 8.883-17.343 24.534-41.736 24.534-28.059 0-47.517-20.163-47.517-49.632 0-29.187 20.163-49.35 47.517-49.35 21.714 0 38.211 11.562 43.146 32.853h-18.048z"/>
    <path :style="{ fill: logoName }" d="M198.868 97h-13.113V2.248h60.348V13.81h-47.235v30.033h43.005v11.421h-43.005V97zM250.154 97h-13.536l36.096-94.752h14.1L323.051 97h-13.677l-9.165-24.957h-40.89L250.154 97zm13.536-36.66h32.289l-16.215-43.851L263.69 60.34zM341.654 97h-12.69V2.248h16.497l29.751 81.78 29.61-81.78h16.497V97h-12.69V24.103L381.416 97h-12.549l-27.213-73.884V97zM447.072 97h-13.113V2.248h13.113V97zM459.78 97V2.248h12.972v83.19h49.209V97H459.78zM541.296 61.045L508.302 2.248h13.818l25.662 47.235 25.662-47.235h13.818l-32.853 58.797V97h-13.113V61.045z"/>
</svg>
</template>

<script>
export default {
  props: {
    logoSymbol: {
      type: String,
      default: 'var(--primary)'
    },
    logoName: {
      type: String,
      default: 'var(--black)'
    }
  }
};
</script>
