export function googleAnalyticsEvent(parameters = {}) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event_name: 'content_clicked',
    'event_parameters': parameters,
    // 'js': new Date()
    // ,'config': import.meta.env.VITE_COUNTRY_GTM
  })
}

export function googleAnalyticsParamEvent(parameters = []) {
  window.dataLayer = window.dataLayer || []

  let dynamicEvent = {}

  for (let index = 0; index < parameters.length; index++) {
    if (index >= 5) {
        break; // Interrompe o loop se o índice for maior ou igual a 5
    }
    dynamicEvent[`dynamic_param${index + 1}`] = parameters[index];
  } 

  window.dataLayer.push({
    event: 'custom_event_microsite'+Math.random(),
    ...dynamicEvent
  })
}

export const GA_EVENTS = {
  SIGNED_UP: 'signed_up',
  ACCOUNT_CONFIRMED: 'account_confirmed',
  PRODUCT_REGISTERED: 'product_registered',
  CAMPAIGN_SIGNED_UP: 'campaign_signed-up'
}