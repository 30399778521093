class TokenService {

    static countryName = import.meta.env.VITE_BASE_PATH.replace("/", "");

    getLocalRefreshToken() {
      const user = JSON.parse(localStorage.getItem("fam-user-member-"+TokenService.countryName));
      return user?.refreshToken;
    }
  
    getLocalAccessToken() {
      const user = JSON.parse(localStorage.getItem("fam-user-member-"+TokenService.countryName));
      return user?.accessToken;
    }

    getLanguage() {
      const user = JSON.parse(localStorage.getItem("fam-user-member-"+TokenService.countryName));
      return user?.language;
    }
  
    updateLocalAccessToken(token) {
      let user = JSON.parse(localStorage.getItem("fam-user-member-"+TokenService.countryName));
      if(user.participant.id)
        user.participant = user.participant.id;
      user.accessToken = token;
      localStorage.setItem("fam-user-member-"+TokenService.countryName, JSON.stringify(user));
    }
  
    getUser() {
      const user = localStorage.getItem("fam-user-member-"+TokenService.countryName)
      if(user)
        return JSON.parse(user);

      return null;
    }
  
    setUser(user) {
      if(user.participant?.id)
        user.participant = user.participant.id;
      localStorage.setItem("fam-user-member-"+TokenService.countryName, JSON.stringify(user));
    }
  
    removeUser() {
      localStorage.removeItem("fam-user-member-"+TokenService.countryName);
    }

    setLang(lang) {
      var us = localStorage.getItem("fam-user-member-"+TokenService.countryName);
      if(us){
        us = JSON.parse(us);
        us.language = lang;
        localStorage.setItem("fam-user-member-"+TokenService.countryName, JSON.stringify(us));
      }
    }
  }
  
  export default new TokenService();
  