<template>
    <section class="wrapper">
        <div class="wrapper--ativacao">

            <div class="content">
                <p>{{ $t('signIn.registerSendTitle') }}</p>
                <span>{{ $t('signIn.registerSendDesc') }}</span>
            </div>

            <figure class="hands">
                <img src="@/assets/img/heart-hands.png" :alt="$t('signIn.registerSendAlt')"
                    :title="$t('signIn.registerSendAlt')">
            </figure>

        </div>
    </section>
</template>

<script>
import AppButton from '@/components/ui/AppButton.vue';
export default {
    components: {
        AppButton
    },

    created(){
		// SEO ======
		document.title = this.$t('meta.ativarConta.title');
		document.querySelector('meta[name="description"]').setAttribute('content', this.$t('meta.ativarConta.description'));
		document.querySelector('meta[name="keywords"]').setAttribute('content', this.$t('meta.ativarConta.keywords'));

		// Definindo as Meta Tags de Open Graph (og:*)
		document.querySelector('meta[property="og:title"]').setAttribute('content', this.$t('meta.ativarConta.title'));
		document.querySelector('meta[property="og:description"]').setAttribute('content', this.$t('meta.ativarConta.description'));
		// // Definindo as Meta Tags do Twitter
		document.querySelector('meta[name="twitter:title"]').setAttribute('content', this.$t('meta.ativarConta.title'));
		document.querySelector('meta[name="twitter:description"]').setAttribute('content', this.$t('meta.ativarConta.description'));
		// SEO ======

  },

}
</script>

<style lang="sass" scoped>
@use '../assets/sass/utilities/_responsive' as *
@use '../assets/sass/utilities/_mixin' as *
.wrapper
    padding-bottom: 0
    flex-grow: 1

.wrapper--ativacao
    flex-grow: 1
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between

    .content
        margin-block: 6rem
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        text-align: center

        p
            font-size: 24px
            font-weight: 700

        span
            font-weight: 500
       

    .hands 
        display: flex
        justify-content: center     

        img
            width: 100%

</style>