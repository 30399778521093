<template lang="">

	<Modal v-if="login.logged" :is-open="exclusiveServiceModalOpened" @close="closeModal">
		<template v-slot:title><span v-html="$t('modalAtendimento.title')"></span></template>
<div class="content--homeModal" v-html="$t('modalAtendimento.text')">

</div>
<div class="buttons--wrap">
	<a :href="whatsappNumber" target="_blank" class="AppButton AppButton__primary small">WhatsApp</a>
</div>
</Modal>
<Modal v-if="!login.logged" :is-open="exclusiveServiceModalOpened" @close="closeModal">
	<template v-slot:title>{{$t('modalAcess.title')}}</template>
	<div class="content--homeModal">
		<p v-html="$t('modalAcess.text')"></p>
	</div>
	<div class="buttons--wrap">
		<Button @interceptAccess="go('/cadastre-se')" type="primary" :buttonText="$t('buttons.fazerCadastro')" />
		<Button @interceptAccess="go('/entrar')" type="secondary" :buttonText="$t('buttons.fazerLogin')" />
	</div>
</Modal>

<button class="float-button" @click.prevent="openModal()">
	<img src="@/assets/img/whats.png" :alt="$t('buttons.floatSupport')" :title="$t('buttons.floatSupport')" />
</button>

</template>
<script>
import AppButton from '@/components/ui/AppButton.vue';
import Button from '@/components/form/Button.vue';
import Modal from '@/components/Modal.vue';
import { mapGetters, mapActions } from 'vuex';
import { googleAnalyticsParamEvent, googleAnalyticsEvent } from '../utils/googleAnalyticsEvents';

export default {
	components: {
		Modal,
		AppButton,
		Button
	},
	data() {
		return {
			isOpen: true,
			whatsappNumber: ''
		}
	},
	mounted() {
		this.getCountry()
	},
	computed: {
		...mapGetters(['exclusiveServiceModalOpened']),
		login: function () {
			return {
				name: this.$store.state.auth.user?.name,
				logged: !!this.$store.state.auth.user
			}
		}
	},
	methods: {
		go(to, element, content, index){
			this.isOpen = false
			googleAnalyticsEvent(['lgfamilyclub', element, content, index, window.location.host+to])
			this.$router.push(to)
		},
		...mapActions(['exclusiveServiceModal']),
		openModal() {
			this.exclusiveServiceModal()
		},
		closeModal() {
			this.exclusiveServiceModal()
		},
		getCountry(){
			this.$store.dispatch('countries/get').then(resp => {
				// console.log(resp.data.data[0])
				this.whatsappNumber = 'https://wa.me/'+ resp.data.data[0].whatsappPhone
			}).catch(e => {
				console.log(e)
			})
		},
	}
}
</script>
<style lang="sass" scoped>
@use '../assets/sass/utilities/_colors' as *
@use '../assets/sass/utilities/_responsive' as *
@use '../assets/sass/utilities/_mixin' as *

.float-button
	--size: 56px

	width: var(--size)
	height: var(--size)
	position: fixed
	z-index: 999
	bottom: 3em
	right: 2em
	border-radius: 100%
	box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px
	transition: all .23s ease-in-out
	animation: slide-in 0.5s ease-out forwards
	animation-delay: 2s
	opacity: 0
	+respond-to($breakpoint-sm-min)
		--size: 86px

	&:hover
		scale: .9
		box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 3px 3px

.content--homeModal
	min-width: 25vw
	p
		@include font-size (1rem, 1.25rem)


@keyframes slide-in 
	from 
		transform: translateX(100%)
		opacity: 0

	to 
		transform: translateX(0)
		opacity: 1

</style>