import api from "./api";

class TutoriaisService {
  get(filters){
    var params = '?';
    if(filters.limit && filters.limit !== null && filters.limit !== '') params+='limit='+filters.limit+'&'
    if(filters.offset && filters.offset !== null && filters.offset !== '') params+='offset='+filters.offset+'&'
    if(filters.search && filters.search !== null && filters.search !== '') params+='search='+filters.search+'&'
    if(filters.tutorialCategoryId && filters.tutorialCategoryId !== null && filters.tutorialCategoryId !== '') params+='tutorialCategoryId='+filters.tutorialCategoryId+'&'

    return api.get(`tutorials${params}`);
  }

  getCategories(){
    return api.get(`tutorials/tutorial-categories`);
  }
}

export default new TutoriaisService();
