

<script>
import moment from 'moment';
import FormInput from '@/components/form/FormInput.vue';
import AppButton from '@/components/ui/AppButton.vue';
import Button from '@/components/form/Button.vue';
import Checkbox from '@/components/form/Checkbox.vue';
import Radiobox from '@/components/form/Radiobox.vue';
import Icons from '@/components/Icons.vue';
import ProductsForm from '@/components/ProductsForm.vue';
import { googleAnalyticsEvent, GA_EVENTS } from '../utils/googleAnalyticsEvents';
import { ttqTrackEvent, fbqTrackEvent } from '../utils/metaEvents';

export default {
	name: 'Entrar',
	components: {
		FormInput,
		AppButton,
		Button,
		Checkbox,
		Radiobox,
		Icons,
		ProductsForm
	},
	data() {
		return {
			cadastroProdutos: true,
			signUpForm: {
				name: "",
				lastName: "",
				cellphone: "",
				birthday: "",
				email: "",
				password: "",
				confirmPassword: '',
				optInAge: false,
				optInTerms: false,
				optInPrivacy: false,
				optInCommunication: false
			},
			sending: false,
			feedback: '',
			created: false,
			category: '',
			categories: [],
			subCategory: '',
			subCategories: [],
			memberId: '',
			productName: '',
			token: '',
			regexCellphone: ''
		};
	},
	created(){
		// SEO ======
		document.title = this.$t('meta.cadastro.title');
		document.querySelector('meta[name="description"]').setAttribute('content', this.$t('meta.cadastro.description'));
		document.querySelector('meta[name="keywords"]').setAttribute('content', this.$t('meta.cadastro.keywords'));

		// Definindo as Meta Tags de Open Graph (og:*)
		document.querySelector('meta[property="og:title"]').setAttribute('content', this.$t('meta.cadastro.title'));
		document.querySelector('meta[property="og:description"]').setAttribute('content', this.$t('meta.cadastro.description'));
		// // Definindo as Meta Tags do Twitter
		document.querySelector('meta[name="twitter:title"]').setAttribute('content', this.$t('meta.cadastro.title'));
		document.querySelector('meta[name="twitter:description"]').setAttribute('content', this.$t('meta.cadastro.description'));
		// SEO ======


		if(this.$router.currentRoute.value.path.includes('validate-user') &&
			this.$route.query?.token){
				this.created = true
				this.token = `Bearer ${this.$route.query.token}`
				const gaEventName = GA_EVENTS.ACCOUNT_CONFIRMED
				const gaParameters = {
					local_time_date : new Date().toISOString(),
				}
				googleAnalyticsEvent(gaEventName, gaParameters)
				fbqTrackEvent('Lead')
				fbqTrackEvent('CompleteRegistration')
				ttqTrackEvent('CompleteRegistration')
		}
		
	},
	mounted(){
		const queryParams = new URLSearchParams(this.$route.query);

		if (queryParams.has('extOrigin')) {
			this.createReferrer(queryParams)
		}

		if (queryParams.has('signup')) {
			this.preSignup(queryParams.get('signup'))
		}
	},
	methods: {
		preSignup(token){
			this.$store.dispatch("auth/preSignup", token).then(
				(resp) => {
					if(resp?.data?.data){
						this.signUpForm.name = resp?.data?.data?.name
						this.signUpForm.lastName = resp?.data?.data?.lastName
						this.signUpForm.cellphone = resp?.data?.data?.cellphone
						this.signUpForm.birthday = resp?.data?.data?.birthday
						this.signUpForm.email = resp?.data?.data?.email
					}
				},
				(error) => {
					console.log(this.error)
				}).catch(error =>{
					console.log(error)
				});
		},
		createReferrer(queryParams){
			const externalOriginKeyValues = {
				type: queryParams.get('extOrigin'),
				keyValues: [],
			};

			// Itera sobre os query params e verifica quais começam com "ext"
			queryParams.forEach((value, key) => {
				console.log(key)
				if (key.startsWith('ext') && key != 'extOrigin') {
					externalOriginKeyValues.keyValues.push({
						value: value,
						key: key.substring(3),
					});
				}
			});
			localStorage.setItem("fam-referrer", JSON.stringify(externalOriginKeyValues));
		},
		interceptAccess(to, element, content, index){
			if(!to) return
			if(to.indexOf('https') >= 0){
				googleAnalyticsEvent(['lgfamilyclub', element, content, index, to])
				return window.open(to, '_blank')
			}
			googleAnalyticsEvent(['lgfamilyclub', element, content, index, window.location.host+to])
			this.$router.push(to)
		},
		sequenceValidation(val) {
			const str = val.toLowerCase();
			const codigoA = 'a'.charCodeAt(0);
			const codigoZ = 'z'.charCodeAt(0);
			const codigo0 = '0'.charCodeAt(0);
			const codigo9 = '9'.charCodeAt(0);

			for (let i = 0; i < val.length - 2; i++) {
				const codigoAtual = val.charCodeAt(i);
				const codigoProximo = val.charCodeAt(i + 1);
				const codigoProximo2 = val.charCodeAt(i + 2);

				if (codigoAtual >= codigoA && codigoAtual <= codigoZ &&
					codigoProximo === codigoAtual + 1 &&
					codigoProximo2 === codigoAtual + 2) {
					return true;
				}

				// Verifica sequência numérica
				if (codigoAtual >= codigo0 && codigoAtual <= codigo9 &&
					codigoProximo === codigoAtual + 1 &&
					codigoProximo2 === codigoAtual + 2) {
					return true;
				}
			}
			return false;
		},
		above18(){
			return moment().subtract(18, 'years').format('YYYY-MM-DD');
		},
		toggleCadastroProdutos() {
			this.cadastroProdutos = !this.cadastroProdutos;
		},
		showProdutos() {
			this.cadastroProdutos = true;
		},
		showFinalizar() {
			this.cadastroProdutos = false;
		},
		getCategories(){
			this.$store.dispatch("product/getCategories").then(
			(resp) => {
				this.categories = resp.data;
			},
			(error) => {
				console.log(error)
			}
			).catch(error =>{
				console.log(error)
			});
		},
		getSubCategories(id){
			this.$store.dispatch("product/getSubCategories", this.category).then(
			(resp) => {
				this.subCategories = resp.data;
			},
			(error) => {
				console.log(error)
			}
			).catch(error =>{
				console.log(error)
			});
		},
		sendSignUp(event){
			this.feedback = ''
			this.sending = true
			event.preventDefault()
			if	(this.isValid) {
				const gaEventName = GA_EVENTS.SIGNED_UP
				const gaParameters = {
				local_time_date : new Date().toISOString(),
				}
				googleAnalyticsEvent(gaEventName, gaParameters)
				fbqTrackEvent('Lead')
				fbqTrackEvent()
				ttqTrackEvent()

				const ref = localStorage.getItem("fam-referrer")

				if(ref)
					this.signUpForm.externalOriginKeyValues = JSON.parse(ref)

				this.$store.dispatch("auth/registerMember", this.signUpForm).then(
				(resp) => {
					console.log(resp?.data?.data?.member?.id)
					if(ref)
						localStorage.removeItem('fam-referrer')
					this.$router.push("/ativar-conta");
				},
				(error) => {
					this.feedback = error.response?.data?.error.message
					console.log(this.feedback)
					if(!this.feedback)
						this.feedback = this.$t('signUp.generalError')
				}).catch(error =>{
					console.log(error)
					this.feedback = this.$t('signUp.generalError')
				});
			}
		},
		sendProduct(event){
			event.preventDefault()
			
			this.$store.dispatch("product/registerProduct", {memberId: this.memberId, subCategoryId: this.subCategory, name: this.productName}).then(
			(resp) => {
				this.$router.push("/entrar?ativarConta=true");
			},
			(error) => {
				console.log(error)
			}
		).catch(error =>{
			console.log(error)
		});
		},
		log(val){
			// console.log(val)
		},
		getRegexCellphone: function(){
			if(!this.regexCellphone)
				this.regexCellphone = new RegExp(`^\\d{${this.cellphoneQtd}}$`)

			return this.regexCellphone
		},
	},
	computed:{
		cellphoneQtd: function(){
			return this.$t('form.labels.telMask').split('#').length -1
		},
		signUpValidation: function(){
			return {
				name: !!this.signUpForm.name,
				lastName: !!this.signUpForm.lastName,
				cellphone: !!this.signUpForm.cellphone && this.getRegexCellphone().test(this.signUpForm.cellphone),
				birthday: !!this.signUpForm.birthday && moment(this.signUpForm.birthday, "YYYY-MM-DD").isBefore(this.above18()),
				email: !!this.signUpForm.email && /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(this.signUpForm.email),
				password: !!this.signUpForm.password,
				passLenght:  this.signUpForm.password.length >= 8 && this.signUpForm.password.length <= 100,
				passCarecteres: !!this.signUpForm.password && (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).+$/.test(this.signUpForm.password)),
				passEmail: !!this.signUpForm.password && this.signUpForm.password.indexOf(this.signUpForm.email) < 0,
				passSequence: !!this.signUpForm.password && !this.sequenceValidation(this.signUpForm.password),
				confirmPassword: !!this.signUpForm.confirmPassword && 
					(this.signUpForm.confirmPassword === this.signUpForm.password),
				// optInAge: !!this.signUpForm.optInAge,
				optInTerms: this.$t('optIns.terms') !== "true" || !!this.signUpForm.optInTerms,
				optInPrivacy: this.$t('optIns.privacy') !== "true" || !!this.signUpForm.optInPrivacy,
				optInCookie: this.$t('optIns.cookie') !== "true" || !!this.signUpForm.optInCookie 
				
			}
		},
		isValid : function(){
			var ret = true
			Object.entries(this.signUpValidation).forEach(([key, value]) => {
				if(!value)
					ret = false;
			});
			return ret;
		},
	},
	beforeRouteEnter(to, from, next) {
		window.scrollTo(0, 0);
		next();
	}
}

</script>

<template>
	<section class="wrapper">
		<!-- Cadastro form ========================================== -->
		<div v-if="!created" class="signUp" >
			<hgroup>
				<h1>{{ $t('signUp.titleSignUp') }}</h1>
			</hgroup>
			<form class="grid login">
				<div class="row">
					<FormInput @input="log(signUpForm.email)"  :label="$t('form.labels.mail')" v-model="signUpForm.email" inputId="mail" 
						inputType="email" :placeholder="$t('form.placeholder.email')" :isRequired="true"
						:col="'col-100'" :isError="sending && !signUpValidation.email" :messageError="$t('form.messageError.email')"/>
			
					<FormInput :label="$t('form.labels.name')" inputId="nome" inputType="text" v-model="signUpForm.name" :placeholder="$t('form.placeholder.name')" :isRequired="true"
						:col="'col-50'" :isError="sending && !signUpValidation.name" :messageError="$t('form.messageError.name')"/>
					
					<FormInput :label="$t('form.labels.sobrenome')" inputId="sobrenome" v-model="signUpForm.lastName" inputType="text" :placeholder="$t('form.placeholder.nameLast')"
						:isRequired="true" :col="'col-50'" :isError="sending && !signUpValidation.lastName" :messageError="$t('form.messageError.lastName')"/>

					<FormInput :label="$t('form.labels.nascimento')" :valorMax="above18()" inputId="data" v-model="signUpForm.birthday" inputType="date" :isRequired="true" 
					:col="'col-100'" :isError="sending && !signUpValidation.birthday" :messageError="$t('form.messageError.birthday')"/>
				
					<FormInput :label="$t('form.labels.celular')" :mask="$t('form.labels.telMask')" inputId="tel" v-model="signUpForm.cellphone" inputType="tel" :placeholder="$t('form.placeholder.tel')"
						:isRequired="true" :col="'col-100'" :isError="sending && !signUpValidation.cellphone" :messageError="$t('form.messageError.cellphone')"/>


					<FormInput :label="$t('form.labels.passwordCreate')" inputId="password" v-model="signUpForm.password" inputType="password" :placeholder="$t('form.placeholder.youPass')" :isRequired="true"
						:col="'col-100'" :isError="sending && !signUpValidation.password" :messageError="$t('form.messageError.passwordCreate')"/>
					

					<ul class="pass--valid">
						<li :class="{ 'valid': (signUpValidation.passLenght)}"><Icons name="check" class="ckeckX"/><Icons name="xMark" class="xMark"/> <span>{{ $t('form.validPass.01') }}</span></li>
						<li :class="{ 'valid': (signUpValidation.passCarecteres)}"><Icons name="check" class="ckeckX"/><Icons name="xMark" class="xMark"/> <span>{{ $t('form.validPass.02') }}</span></li>
						<li :class="{ 'valid': (signUpValidation.passEmail)}"><Icons name="check" class="ckeckX"/><Icons name="xMark" class="xMark"/> <span>{{ $t('form.validPass.03') }}</span></li>
						<li :class="{ 'valid': (signUpValidation.passSequence)}"><Icons name="check" class="ckeckX"/><Icons name="xMark" class="xMark"/>  <span>{{ $t('form.validPass.04') }}</span></li>
					</ul>
					<FormInput :label="$t('form.labels.passRepeat')" v-model="signUpForm.confirmPassword" inputId="passwordRepeat" inputType="password" :placeholder="$t('form.placeholder.youPassConfirm')"
						:isRequired="true" :col="'col-100'" :isError="sending && !signUpValidation.confirmPassword" :messageError="$t('form.messageError.confirmPassword')"/>		

				</div>
				<div class="row checkbox">

					<!-- MOVOS ========== -->
					<div class="checkbox-wrapper">
						<div>
							<input v-model="signUpForm.optInTerms" type="checkbox" aria-hidden="true">
							<label v-html="$t('form.labels.optInTerms')"></label>
						</div>
						<span class="alertErro" v-show="sending && !signUpValidation.optInTerms">{{ $t('form.messageError.optInPrivacy') }}</span>
					</div>

					<div class="checkbox-wrapper">
						<div>
							<input v-model="signUpForm.optInCookie" type="checkbox" aria-hidden="true">
							<label v-html="$t('form.labels.optInCookie')"></label>
						</div>
						<span class="alertErro" v-show="sending && !signUpValidation.optInCookie">{{ $t('form.messageError.optInPrivacy') }}</span>
					</div>

					<div class="checkbox-wrapper">
						<div>
							<input v-model="signUpForm.optInPrivacy" type="checkbox" aria-hidden="true">
							<label v-html="$t('form.labels.optInPrivacy')"></label>
						</div>
						<span class="alertErro" v-show="sending && !signUpValidation.optInPrivacy">{{ $t('form.messageError.optInPrivacy') }}</span>
					</div>
					
				</div>
				<div class="row buttons--wrap">
					<AppButton @click="sendSignUp($event)" variant="primary">{{ $t('buttons.enviar') }}</AppButton>
				</div>
				<span class="serverReturn" v-show="feedback">{{feedback}}</span>
			</form>
			

		</div>
		<!-- Cadastro form ========================================== -->


		<div v-if="created" class="signUp--finish">
			<header>
				<figure>
					<img src="@/assets/img/avatar-girl.png" :alt="$t('signUp.titleSignUpThanks')"
						:title="$t('signUp.titleSignUpThanks')">
				</figure>
				<hgroup>
					<h1>{{ $t('signUp.titleSignUpThanks') }}</h1>
					<p>{{ $t('signUp.descSignUpThanks') }}</p>
				</hgroup>
			</header>

			<form class="grid">
				<div class="box--combo">
					<Radiobox id="afterProd" @click="showFinalizar">{{ $t('signUp.signUpOpt1') }}</Radiobox>
					<Radiobox id="prod" checked @click="showProdutos">{{ $t('signUp.signUpOpt2') }}</Radiobox>

				</div>

				<ProductsForm v-show="cadastroProdutos" :token="token"/>
			
				<div class="row buttons--wrap finalizar">
					<Button type="primary" :buttonText="$t('buttons.goLogin')" @interceptAccess="interceptAccess('/entrar?ativarConta=true')"/>
				</div>
			</form>
		</div>
	</section>
</template>

<style lang="sass" scoped>
@use '../assets/sass/utilities/_colors' as *
@use '../assets/sass/utilities/_responsive' as *
@use '../assets/sass/utilities/_mixin' as *



.clear
	clear: both	
	display: block

.alertErro
	user-select: none
	font-size: 0.75rem
	width: fit-content
	background: rgba(var(--lg-red-rgb), .085)
	padding: 5px 10px
	color: var(--lg-red-active)
	border-radius: 6px
	font-weight: 600
	align-items: center
	gap: 6px

	i
		display: flex
		width: 1rem

		svg
			width: 100%



.wrapper
	width: min(100%, 550px)
	margin-inline: auto
	margin-block: 4rem

	hgroup
		margin-bottom: 3.125rem
		h1
			font-weight: 700
			font-size: clamp( 1.40rem, var(--clamp), 2rem)

	.row
		gap: 10px
		
		&.checkbox
			gap: 10px
			margin-top: 1rem
		
		&.buttons--wrap
			margin-top: 2rem
			justify-content: center


.signUp, .signUp--finish
	padding-inline: 1rem
	+respond-to($breakpoint-sm-min)	
		padding-inline: 0	

.pass--valid
	margin: 0
	margin-top: -10px
	padding: 0
	list-style: none
	border-radius: 8px
	padding: 1rem
	width: 100%
	
	background-color: var(--lg-light-gray-300)

	li
		font-size: 11px
		font-weight: 600
		color: var(--lg-red-active)
		display: flex
		gap: 4px
		transition: all .23s ease

		i
			width: 15px
			

			&.xMark
				display: block
			
			&.ckeckX
				display: none

		&.valid
			color: var(--green-10)

			i			

				&.xMark
					display: none
				
				&.ckeckX
					display: block

.checkbox-wrapper
	font-size: 14px
	

	
		
	

.signUp--finish

	header
		display: flex
		flex-direction: column
		align-items: center
		border-bottom: 1px solid var(--color-grey-1300)

		hgroup
			margin-bottom: 1.5rem
			text-align: center
			
			h1
				color: var(--lg-red-active)
				@include font-size (1.25rem, 1.875rem)

			p	
				color: var(--color-grey-1400)
				@include font-size (1rem, 1.375rem)

		figure
			max-width: 240px

			img
				width: 100%

	.box--combo
		display: flex
		flex-direction: column
		align-items: flex-start
		margin-inline: auto
		gap: 10px
		margin-block: 2rem





</style>
	