import { googleAnalyticsParamEvent, googleAnalyticsEvent } from '../utils/googleAnalyticsEvents';
import router from "@/router";

const evt = 'lgfamilyclub'
const basePath = import.meta.env.VITE_BASE_PATH

export const route = {
  namespaced: true,
  actions: {
    click({ rootState, dispatch }, { to, element, content, index }) {
      if(rootState.auth.user)
        if(to.indexOf('lgfamilyclub.com') < 0 && to.indexOf('/') !== 0){
          if(to.indexOf('https://')<0)
            to='https://'+to
            dispatch('gaEvent', {element, content, index, to}).then(()=> {
              window.open(to, (this.isTabSelf?'_self':'_blank'))
            })
        }else{
          if(to.indexOf(basePath) >= 0)
            to = to.substring(to.indexOf(basePath)+3)
            dispatch('gaEvent', {evt, element, content, index, to: window.location.host+to }).then(()=> {
              router.push(to)
            })
        }
      else if (['/entrar', '/cadastre-se'].includes(to)){
        dispatch('gaEvent', {evt, element, content, index, to: window.location.host+to }).then(()=> {
          router.push(to)
        })
      }
      else
        return Promise.reject('N/D');
    },

    gaEvent({ state }, element, content, index, to){
      if(content == 'banner')
        googleAnalyticsEvent(['lgfamilyclub', element, content, index, to])
      else{
        googleAnalyticsParamEvent(['lgfamilyclub', element, content, index, to])
      }
    }
  },
  mutations: {
  },
  getters: {
  },
};
