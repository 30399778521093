export function googleAnalyticsEvent(name = '', parameters = {}) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    'event_name': name,
    'event_parameters': parameters,
    'js': new Date()
    // ,'config': import.meta.env.VITE_COUNTRY_GTM
  })
}

export const GA_EVENTS = {
  SIGNED_UP: 'signed_up',
  ACCOUNT_CONFIRMED: 'account_confirmed',
  PRODUCT_REGISTERED: 'product_registered',
  CAMPAIGN_SIGNED_UP: 'campaign_signed-up'
}