import AuthService from '../services/auth.service';

const user = AuthService.getCurrentUser();
const initialState = user
  ? { status: { justLogged: false, loggedIn: true , reset:{email: "", reseted: false}}, user }
  : { status: { justLogged: false, loggedIn: false , reset:{email: "", reseted: false}}, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    resetPasswordToken({ commit }, form) {
      return AuthService.resetPasswordToken(form).then(
        resp => {
          return Promise.resolve(resp);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    tokenResetValidation({ commit }, token) {
      return AuthService.tokenResetValidation({token}).then(
        resp => {
          return Promise.resolve(resp);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    login({ commit }, user) {
      return AuthService.login(user).then(
        resp => {
          commit('loginSuccess', resp.data);
          return Promise.resolve(resp.data);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    token({ commit }, username) {
      return AuthService.token(username).then(
        () => {
          commit('token', username);
          return Promise.resolve("DONE");
        },
        error => {
          commit('tokenFail');
          return Promise.reject(error);
        }
      );
    },
    reset({ commit }, user) {
      return AuthService.reset(user).then(
        () => {
          commit('reset');
          return Promise.resolve("DONE");
        },
        error => {
          commit('resetFail');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    registerMember({ commit }, part) {
      return AuthService.registerMember(part).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    refreshToken({ commit }, accessToken) {
      commit('refreshToken', accessToken);
    },
    refreshTokenStart({ commit }) {
      AuthService.refreshToken().then(
        user => {
          commit('refreshTokenStart', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        })
    },
    async changeLang({ commit }, arr) {
      await AuthService.changeLang(arr[0]?.toString(), 
        arr[1]?.toString())
      .then(resp => {
        commit('lang', resp?.data?.language);
      })
      .catch(e =>{console.log('EROR CHANGING LANG: '+e)});
    },
    async updateUser({commit, state}, user){
      commit('updateUser', user);
      AuthService.setUser(state.user)
    },
    preSignup({ commit }, token) {
      return AuthService.preSignup(token).then(
        resp => {
          return Promise.resolve(resp);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    updateUser(state, user){
      console.log(state)
      state.user = {
        ...state.user,
        name: user.name,
        lastName: user.lastName,
        birthday: user.birthday,
        // Atualiza o objeto aninhado `member`
        member: {
          ...state.user.member,
          cellphone: user.member.cellphone
        }
      };
    },
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      // if(user.member.id)
      //   user.member = user.member.id;
      state.user = user;
      state.justLogged = true;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    refreshToken(state, accessToken) {
      state.status.loggedIn = true;
      state.user = { ...state.user, accessToken: accessToken };
    },
    refreshTokenStart(state, user) {
      state.status.loggedIn = true;
      if(user.participant.id)
        user.participant = user.participant.id;
      state.user = user;
    },
    token(state, username) {
      state.status.reset.email = username;
      state.status.token = true;
    },
    tokenFail(state) {
      state.status.token = false;
    },
    reset(state) {
      state.status.reset.reseted = true;
    },
    lang(state, lang) {
      if(state.user)
        state.user.language = lang;
    },
  }
};
