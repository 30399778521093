import ProductsService from '../services/products.service';

export const products = {
  namespaced: true,
  actions: {
    get({ commit }, filters) {
      if(!filters)
        filters = {}
      return ProductsService.get(filters)
      // .then(
      //   resp => {
      //     return Promise.resolve(resp);
      //   },
      //   error => {
      //     return Promise.reject(error);
      //   }
      // );
    },

    getMenus({ commit }) {
      return ProductsService.getMenus();
    }
  },
  mutations: {
  }
};
