import qs from 'qs'

export function buildQueryParams(params) {
  const filteredParams = Object.keys(params).reduce((acc, key) => {
    if (params[key] !== null && params[key] !== undefined && params[key] !== '') {
      acc[key] = params[key];
    }
    return acc;
  }, {});
  return qs.stringify(filteredParams);
}