import UserService from '../services/user.service';

export const user = {
  namespaced: true,
  actions: {
    update({ commit }, user) {
      return UserService.update(user);
    },
    campaignPost({ commit }, body) {
      return UserService.campaignPost(body);
    },
  
    campaignGet({ commit }, id) {
      return UserService.campaignGet(id);
    }
  },
  mutations: {
    }
};
