import axios from "./api";

class SocialMedias {
  get(){
    return axios.get(`/countries/social-medias`);
  }
  getTypes() {
    return axios.get(`/countries/social-medias/social-media-types`);
  }
}

export default new SocialMedias();
