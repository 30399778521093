import ExperienciasService from '../services/experiencias.service';

export const experiencias = {
  namespaced: true,
  actions: {
    getExclusiveExperience({ commit }, id) {
      return ExperienciasService.getExclusiveExperience(id)
        .then(
          (user) => {
            return Promise.resolve(user);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((e) => {
          return Promise.reject(e);
        });
    },
    getExclusiveExperiences({ commit }, params) {
      return ExperienciasService.getExclusiveExperiences(params)
        .then(
          (users) => {
            return Promise.resolve(users);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((e) => {
          return Promise.reject(e);
        });
    },
    exclusiveExperiencesRegister({ commit }, request) {
      return ExperienciasService.exclusiveExperiencesRegister(request)
        .then(
          (user) => {
            return Promise.resolve(user);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .catch((e) => {
          return Promise.reject(e);
        });
    },
  },
  mutations: {
  },
  getters: {
  },
};