<template>
	<transition name="fade" mode="out-in">
		<div v-if="isOpen" class="modal-overlay" @click="closeModal">
			<div class="modal-content" @click.stop>
				<header class="modal-content--header">
					<h2 class="titleModal">
						<slot name="title"></slot>
					</h2>
					<button class="close" @click="closeModal">
						<Icons name="xMark" />
					</button>
				</header>
				<slot />
				<div class="modal--ovelary" v-if="isOpen"></div>
			</div>
		</div>
	</transition>
</template>

<script>

import Icons from '@/components/Icons.vue';

export default {
	name: 'Modal',
	components: {
		Icons
	},
	props: {
		isOpen: Boolean
	},
	methods: {
		closeModal() {
			this.$emit('close');
		}
	},
	mounted() {
		window.addEventListener('keydown', (e) => {
			if (e.key === 'Escape' && isOpen) this.closeModal();
		});
	},
	watch: {
		isOpen: (n) => {
			if (n) {
				document.body.style.paddingRight = '';
				document.body.style.overflow = 'hidden';
			}
			else {
				document.body.style.paddingRight = '';
				document.body.style.overflow = '';
			}
		}
	},
	beforeDestroy() {
		document.body.style.overflow = '';
	},
};
</script>

<style lang="sass" scoped>
@use '../assets/sass/utilities/_colors' as *
@use '../assets/sass/utilities/_responsive' as *
@use '../assets/sass/utilities/_mixin' as *

.fade-enter-active, .fade-leave-active 
	transition: opacity 0.2s ease

.fade-enter-from, .fade-leave-to 
	opacity: 0	

.modal-overlay
	--blur-modal: 4px

	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 100%
	background-color: rgba(var(--black-rgb), .6 )
	backdrop-filter: blur(var(--blur-modal))
	display: flex
	justify-content: center
	align-items: center
	z-index: 99999

.modal-content
	--pad: 2rem

	border: 1px solid #000
	min-width: 20vw
	padding: 2rem
	gap: 2rem
	background-color: var(--white)
	border-radius: var(--r-card)

	+respond-to($breakpoint-lg-min)
		max-width: fit-content

	&--header
		display: flex
		justify-content: center
		position: relative
	
		h2
			@include font-size (1.5625rem, 1.875rem)
			font-weight: 600

	.close
		position: absolute
		background: none
		width: 40px
		padding: 7px
		right: calc( calc(var(--pad) * -1) + 1rem )
		border-radius: 50%
		top: -50%
		transition: all .23s ease

		&:hover
			color: var(--lg-red-active)
			background: rgba(var(--lg-red-active-rgb), .1)

</style>