<template>
	<div :class="['box--form', col, { error: isError }, { 'checkbox-input': inputType === 'checkbox' }]">
		<label :for="inputId" :class="{ required: isRequired }">{{ label }}</label>
		<input 
			@input="applyMask($event.target.value, mask)" 
			:max="valorMax" 
			:type="inputType" 
			:name="inputId" 
			:id="inputId" 
			:placeholder="placeholder" 
			:disabled="isDisabled" 
			v-model="formatedValue"
			:value="formatedValue">
		<span v-show="isError" class="alertErro"><Icons name="exclamation"/> {{messageError}}</span>
	</div>
</template>
<!-- @input="$emit('update:modelValue', $event.target.value)" -->
<script>
import Icons from '@/components/Icons.vue';
// :value="modelValue"
export default {
	components:{
		Icons
	},
	data(){
		return{
			formatedValue: "",
			// origValue: ""
		}
		
	},
	created(){
		this.formatedValue = this.modelValue
	},
	watch: {
		modelValue(newValue) {
			this.formatedValue = newValue;
			this.applyMask(this.formatedValue, this.mask)
		}
	},
	methods: {
		applyMask(valor, mascara) {
			if(!mascara){
				this.$emit('update:modelValue', valor);
				return;
			}
			let valorLimpo = valor.replace(/\D/g, ''); // Remove tudo que não é dígito
			
			let valorMascarado = '';
			let indiceMascara = 0;
			let indiceValor = 0;

			// Itera até que todos os dígitos do valor sejam consumidos ou a máscara seja totalmente percorrida
			while (indiceValor < valorLimpo.length && indiceMascara < mascara.length) {
				if (mascara[indiceMascara] === '#') {
				valorMascarado += valorLimpo[indiceValor++];
				} else {
				// Garante que caracteres de máscara sejam adicionados desde o início
				valorMascarado += mascara[indiceMascara];
				}
				indiceMascara++;
			}

			this.formatedValue = valorMascarado
			this.$emit('update:modelValue', valorMascarado.replace(/\D/g, ''));
			// Retorna o valor com a máscara aplicada até onde foi possível, sem adicionar extras no final
			// return valorMascarado;
		}
	},
	props: {
		modelValue: String || Boolean || Array || Number,
		placeholder: {
			type: String,
			required: false,
		},
		label: {
			type: String,
			required: true,
		},
		inputId: {
			type: String,
			required: true,
		},
		inputType: {
			type: String,
			default: 'text',
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		col: {
			type: String,
			default: '',
		},
		isError: {
			type: Boolean,
			default: false,
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
		mask: String,
		messageError:String,
		valorMax:String
	},
};
</script>


<style lang="sass" scoped>
@use '../../assets/sass/utilities/_colors' as *
@use '../../assets/sass/utilities/_responsive' as *
.box--form
	display: flex
	flex-direction: column
	width: 100%
	gap: 6px

	label
		font-weight: 500
		font-size: .85rem
		color: var(--magenta--title-default)

		
	
		&.required
			&::after
				content: '*'
				display: inline-block
				color: var(--lg-red-active)
				font-size: 12px
				top: 0
				margin-left: 1px
				vertical-align: text-top

	input
		width: 100%
		height: 2.5rem
		border-radius: 8px
		padding: 5px
		transition: all .20s ease-in-out
		border: 1px solid var(--color-grey-700)
		font-size: .85rem
		display: block
		background-color: var(--white)
		-webkit-appearance: textfield
		-moz-appearance: textfield
	
		
		&:hover
			border: 1px solid var(--lg-red)

		&:focus
			border: 1px solid var(--lg-red)
			box-shadow: 0px 0px 0px 4px rgba(var(--lg-red-rgb), .11 )

		&:disabled
			cursor: not-allowed
			background: var(--color-grey-100)
			&:hover
		
				border: 1px solid var(--color-grey-1000)		

		&::placeholder
			color: var(--lg-mid-gray-200)
			
	&:where(.error)
		.alertErro
			display: flex

		input
			border: 1px solid var(--lg-red-active)
			background: rgba(var(--lg-red-active-rgb), .035)

			&::placeholder
				color: var(--lg-red-active)

	&.checkbox-input
		flex-direction: row
		align-items: center

		label
			order: 1
			font-size: .8125rem
		input
			order: 0
			width: .875rem
			height: .875rem
	
	input[type="date"]
		min-width: 96%
		+respond-to($breakpoint-sm-min)
			min-width: 100%
	



.alertErro
	display: none
	user-select: none
	font-size: 0.75rem
	width: fit-content
	background: rgba(var(--lg-red-rgb), .085)
	padding: 5px 10px
	color: var(--lg-red-active)
	border-radius: 6px
	font-weight: 600
	align-items: center
	gap: 6px

	i
		display: flex
		width: 1rem

		svg
			width: 100%




</style>
	