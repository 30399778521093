import axios from "./api";

class Analytics {
  get(){
    return axios.get(`countries/analytics`);
  }
  getTypes() {
    return axios.get(`countries/analytics/analytic-types`);
  }
}

export default new Analytics();
