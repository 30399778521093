<template>
	<div class="prodsCadastro grid">

		<div class="row">
			<div class="box--input--custom">
				<Icons name="userIcon" />
				<select @change="getSubCategories()" v-model="category" name="categoria" id="categoria">
					<option disabled value="" selected>{{ $t('form.selectProd.categoria') }}</option>
					<option v-for="category in categories" :value="category.id">{{ category.name }}</option>
				</select>
				<Icons name="arrowDown" class="arrow" />
			</div>
		</div>

		<div v-show="subCategories && subCategories.length > 0" class="row">
			<div class="box--input--custom">
				<Icons name="catProd" />
				<select v-model="subCategory" name="SubCategoria" id="SubCategoria">
					<option value="" disabled selected>{{ $t('form.selectProd.subCategoria') }}</option>
					<option v-for="subCategory in subCategories" :key="subCategory.id" :value="subCategory.id">
						{{ subCategory.name }}</option>
				</select>
				<Icons name="arrowDown" class="arrow" />
			</div>

		</div>

		<div v-show="subCategory" class="row">
			<div class="box--input--custom">
				<Icons name="prodIcon" />
				<input v-model="productName" type="text" :placeholder="$t('form.selectProd.produto')">
			</div>
		</div>

		<div class="row buttons--wrap">
			<AppButton @click="sendProduct($event)" variant="black">{{ $t('buttons.cadastrar') }}</AppButton>
		</div>
	</div>
</template>

<script>
import Icons from '@/components/Icons.vue';
import AppButton from '@/components/ui/AppButton.vue';
import { googleAnalyticsEvent, GA_EVENTS } from '../utils/googleAnalyticsEvents';

export default {
	components: {
		Icons,
		AppButton
	},
	props:{
		token: String
	},
	data(){
		return{
			category: '',
			categories: [],
			subCategory: '',
			subCategories: [],
			productName: ''
		}
	},
	created(){
		this.getCategories();		
	},
	methods: {
		getCategories(){
			this.category = ''
			this.subCategory = ''
			this.$store.dispatch("product/getCategories").then(
			(resp) => {
				this.categories = resp.data;
			},
			(error) => {
				console.log(error)
			}
			).catch(error =>{
				console.log(error)
			});
		},
		getSubCategories(id){
			this.subCategory = ''
			this.$store.dispatch("product/getSubCategories", this.category).then(
			(resp) => {
				this.subCategories = resp.data;
			},
			(error) => {
				console.log(error)
			}
			).catch(error =>{
				console.log(error)
			});
		},
		sendProduct(event){
			event.preventDefault()
			const category = this.categories.find(category => category.id == this.category)
			const subCategory = this.subCategories.find(subCategory => subCategory.id == this.subCategory)
			const gaEventName = GA_EVENTS.PRODUCT_REGISTERED
			const gaParameters = {
				local_time_date : new Date().toISOString(),
				p_item_category1: category.name,
				p_item_category2:	subCategory.name,
				p_user_friendly_name: this.productName
			}
			googleAnalyticsEvent(gaEventName, gaParameters)
			this.$store.dispatch("product/registerProduct", {token: this.token, subCategoryId: this.subCategory, name: this.productName}).then(
				(resp) => {
					if(!this.$router.currentRoute.value.path.includes('minha-area'))
						this.$router.push("/entrar");
					else
						this.$emit('send', true)
				},
				(error) => {
					console.log(error)
				}
			).catch(error =>{
				console.log(error)
			}).finally(() => {
				if(this.$router.currentRoute.value.path.includes('minha-area'))
					this.$emit('close')
			});
		}
	}
}
</script>

<style lang="sass" scoped>
@use '../assets/sass/utilities/_colors' as *
@use '../assets/sass/utilities/_responsive' as *
@use '../assets/sass/utilities/_mixin' as *
@use '../assets/sass/components/_modal' as *

.prodsCadastro

	.box--input--custom
		--bdColor: var(--black)

		position: relative
		z-index: 1
		width: 100%
		border-bottom: 0.105rem solid var(--bdColor)
		padding-block: 15px
		display: flex
		flex-direction: row
		align-items: center
		gap: 10px
		transition: all 0.23s ease
		
		i
			display: block
			width: 30px

		select, input
			height: 50px
			z-index: 2
			-webkit-appearance: none
			-moz-appearance: none
			appearance: none
			width: 100%
			background: transparent
			color: var(--black) !important

			option
				&:selected
					font-size: 25px

			
			@supports not (background: transparent)
				background-color: var(--lg-light-gray-100)

		.arrow
			width: 20px
			z-index: 1
			right: 0
			position: absolute
			stroke-width: 1.9

		&:hover
			color: var(--lg-red-active)
			--bdColor: var(--lg-red-active)
.buttons--wrap
	margin-top: 2rem

</style>