<template>
	<div id="loader"><div id="loader--content"><picture id="loader--icon"><source type="image/webp" srcset="/src/assets/img/basic/icon-loading.webp" alt="Carregando" title="Carregando"><img src="/src/assets/img/basic/icon-loading.gif" alt="Carregando" title="Carregando"></picture></div></div>
</template>

<script>
export default {
  name: 'Loader',

//   mounted() {
//     function initLoader() {
//       document.querySelector("#app").style.visibility = "hidden";
//       document.querySelector("#loader").style.visibility = "visible";
//     }

//     function initApp() {
//       document.querySelector("#loader").style.display = "none";
//       document.querySelector("#app").style.visibility = "visible";
//       $("#app").addClass("animate__animated animate__fadeIn"); 
//     }

//     document.addEventListener("readystatechange", (event) => {
//       if (event.target.readyState === "interactive") {
//         initLoader();
//       } else if (event.target.readyState === "complete") {
//         initApp();
//       }
//     });
//   },
}
</script>

<style lang="sass" scoped>
@use '../assets/sass/utilities/_colors' as *
@use '../assets/sass/utilities/_responsive' as *
@use '../assets/sass/utilities/_mixin' as *
#loader	
	overflow: hidden
	position: fixed
	z-index: 10000
	top: 0
	bottom: 0
	left: 0
	right: 0
	height: 100vh
	width: 100vw
	display: flex
	align-items: center
	justify-content: center
	flex-direction: column 
	background: var(--lg-light-gray-100)

	#loader--content
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center

		#loader--icon
			width: 85px
			img
				width: 100%
				// filter: invert(1)

</style>
	
