<template>
<div class="main"> 
	<Header />
	<section class="warpperMain">
		<RouterView />
	</section>
	
	<Footer :socialMedias="socialMedias" :country="country" />

</div>	
</template>

<script>
import Header from '@/components/template/Header.vue'
import Footer from '@/components/template/Footer.vue'


export default {
	name: 'logged',
	components: {
		Header,
		Footer,

	},
	data(){
		return {
			aux: import.meta.env.VITE_BASE_PATH,
			socialMedias: [],
			country: {}
		}
	},
	mounted() {
		this.getCountry()
		this.getSocialMedias()
		const queryParams = new URLSearchParams(this.$route.query);

		if (queryParams.has('extOrigin')) {
			this.createReferrer(queryParams)
		}
	},
	methods: {
		createReferrer(queryParams){
			const externalOriginKeyValues = {
				type: queryParams.get('extOrigin'),
				keyValues: [],
			};

			// Itera sobre os query params e verifica quais começam com "ext"
			queryParams.forEach((value, key) => {
				console.log(key)
				if (key.startsWith('ext') && key != 'extOrigin') {
					externalOriginKeyValues.keyValues.push({
						value: value,
						key: key.substring(3),
					});
				}
			});
			localStorage.setItem("fam-referrer", JSON.stringify(externalOriginKeyValues));
		},
		getCountry(){
			this.$store.dispatch('countries/get').then(resp => {
				this.country = resp.data.data[0]
			}).catch(e => {
				console.log(e)
			})
		},
		getSocialMedias(){
			this.$store.dispatch('socialMedias/get').then(resp => {
				this.socialMedias = resp.data.data
			}).catch(e => {
				console.log(e)
			})
		},
	}
}
</script>

<style lang="sass" scoped>
@use '../assets/sass/utilities/_colors' as *
@use '../assets/sass/utilities/_responsive' as *



.main
	display: flex
	flex-direction: column
	min-height: 100vh

.warpperMain
	flex-grow: 1
	margin-inline: auto
	width: 100%
	display: flex
	flex-direction: column


</style>