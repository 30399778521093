import api from "./api";

class ProductsService {
  get(filters){
    var params = '?';
    if(filters.limit && filters.limit !== null && filters.limit !== '') params+='limit='+filters.limit+'&'
    if(filters.offset && filters.offset !== null && filters.offset !== '') params+='offset='+filters.offset+'&'
    if(filters.search && filters.search !== null && filters.search !== '') params+='search='+filters.search+'&'
    if(filters.saleOfferCategoryId && filters.saleOfferCategoryId !== null && filters.saleOfferCategoryId !== '') params+='saleOfferCategoryId='+filters.saleOfferCategoryId+'&'

    return api.get(`sale-offers${params}`);
  }

  getMenus(){
    return api.get(`sale-offers/sale-offer-categories`);
  }

}

export default new ProductsService();
