import api from "./api";

class UserService {
  update(user) {
    if(!user || !user?.member?.id)
      throw 'INVALID PARAMETERS'
    return api.put(`/users/members/${user.member.id}`, user)
  }

  campaignPost(body) {
    return api.post(`/campaigns/${body.campaignId}`, body)
  }

  campaignGet(id) {
    return api.get(`/campaigns/${id}`)
  }
}

export default new UserService();
