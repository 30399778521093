import { createI18n } from 'vue-i18n'
import langJson from './internationalization.json'

const i18n = createI18n({
    locale: 'pt-br',
    fallbackLocale: 'es',
    messages: loadInternationalization(),
})

function loadInternationalization(){
    const messages = {pt:{}, es:{}, ar:{}, ec:{}, cl:{}, co:{}, mx:{}, pe:{}, pa:{}}
    itObj(langJson, messages.es, messages.pt, messages.ar, messages.ec, messages.cl, messages.co, messages.mx, messages.pe, messages.pa);
    return messages
}

function itObj(array, es, pt, ar, ec, cl, co, mx, pe, pa, node){
    for (const sub in array) {
        if(typeof array[sub] === 'object'){
            let oldPt = pt;
            let oldes = es;
            let oldar = ar;
            let oldec = ec;
            let oldcl = cl;
            let oldco = co;
            let oldmx = mx;
            let oldpe = pe;
            let oldpa = pa;
            if(!es.hasOwnProperty(sub) && !array[sub].hasOwnProperty("es")){
                es[sub] = {}
                es = es[sub]
            }else
                node = sub
            if(!pt.hasOwnProperty(sub) && !array[sub].hasOwnProperty("pt")){
                pt[sub] = {}
                pt = pt[sub]
            }else
                node = sub
            if(!ar.hasOwnProperty(sub) && !array[sub].hasOwnProperty("ar")){
                ar[sub] = {}
                ar = ar[sub]
            }else
                node = sub  
            if(!ec.hasOwnProperty(sub) && !array[sub].hasOwnProperty("ec")){
                ec[sub] = {}
                ec = ec[sub]
            }else
                node = sub
            if(!cl.hasOwnProperty(sub) && !array[sub].hasOwnProperty("cl")){
                cl[sub] = {}
                cl = cl[sub]
            }else
                node = sub
            if(!co.hasOwnProperty(sub) && !array[sub].hasOwnProperty("co")){
                co[sub] = {}
                co = co[sub]
            }else
                node = sub
            if(!mx.hasOwnProperty(sub) && !array[sub].hasOwnProperty("mx")){
                mx[sub] = {}
                mx = mx[sub]
            }else
                node = sub
            if(!pe.hasOwnProperty(sub) && !array[sub].hasOwnProperty("pe")){
                pe[sub] = {}
                pe = pe[sub]
            }else
                node = sub  
            if(!pa.hasOwnProperty(sub) && !array[sub].hasOwnProperty("pa")){
                pa[sub] = {}
                pa = pa[sub]
            }else
                node = sub
            itObj(array[sub], es, pt, ar, ec, cl, co, mx, pe, pa, node)
            pt = oldPt
            es = oldes
            ec = oldec;
            ar = oldar; 
            cl = oldcl;
            co = oldco;
            mx = oldmx;
            pe = oldpe;
            pa = oldpa;
        }
        else{
            if(sub =='pt') pt[node] = array.pt;
            if(sub =='es') es[node] = array.es;
            if(sub =='ec') ec[node] = array.ec;
            if(sub =='ar') ar[node] = array.ar;
            if(sub =='cl') cl[node] = array.cl;
            if(sub =='co') co[node] = array.co;
            if(sub =='mx') mx[node] = array.mx;
            if(sub =='pe') pe[node] = array.pe;
            if(sub =='pa') pa[node] = array.pa;
        }
    }
}

export {i18n};
