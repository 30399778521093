export function fbqTrackEvent(event = 'PageView') {
  if (window.fbq) {
   return fbq('track', event);
  }
  return null;
}

export function ttqTrackEvent(event = 'PageView') {
  if (window.ttq) {
   return ttq.track(event);
  }
  return null;
}