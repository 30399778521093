import ContentsService from "../services/contents.service";

export const contents = {
  namespaced: true,
  actions: {
    get({}, filters = {}) {
      return ContentsService.get(filters)
    }
  }
};
